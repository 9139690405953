<template>
  <div class="pages-home-trades-main outer-most" style="color: var(--main-color);">
    <div class="ant-table-wrapper pages-home-trades-table" v-if="all_sales_promotion">
      <div style="margin-left: 34%; z-index: 999;position: relative;">
        <div style="width: 133px; height: 75px; float: left;background-size: 100% auto; background-repeat: no-repeat; background-image: var(--sale_promotion_success_left); text-align: center; padding-top: 3.5%;color: #ffffff;">{{$t('fight_new')}}</div>
        <div style="float: left; width: 60px; height: 60px; background-color: #ffffff; border-radius: 50px; margin: 6px 5px 4px -10px;">
          <!-- <img style="margin: 3px 0px 3px 3px;width: 55px; height: 55px;border-radius: 50px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2235/色相_饱和度 1050.png"> -->
          <img style="margin: 3px 0px 3px 3px; width: 55px; height: 55px;border-radius: 50px;" :src="all_sales_promotion.funding_currency_icon_url">
        </div>
        <div style="float: left; width: 60px; height: 60px; background-color: #ffffff; border-radius: 50px; margin-top: 6px;">
          <img style="margin: 3px 0px 3px 3px; width: 55px; height: 55px;border-radius: 50px;" :src="all_sales_promotion.currency_icon_url">
        </div>

        <div style="width: 133px; height: 75px; float: left;background-size: 100% auto; background-repeat: no-repeat; background-image: var(--sale_promotion_success_right); text-align: center; padding-top: 3.5%;color: #ffffff; margin-left:  -10px;">{{$t('success')}}</div>
      </div>
      <div class="first-built-in" style="float: left; height: 100%; margin-top: -37px; padding: 60px 30px 30px 30px;">
        <div style="width: 100%; height: 156px; background-image: url(https://showmethemoney.fuxinsci.com/image_after_2019_06/2240/success_bannder.png); background-size: 100% 100%; border-radius: 10px;">
          <div style="float: left; margin: 30px 0px 0px 30px">
            <span style="color: #c99bff">{{$t('we_have_received_your_participation')}}：</span>
            <div style="margin-top: 45px">
              <span style="font-size: 30px; color: #fff; font-weight: bold">{{all_sales_promotion.user_last_sale_token_fundraisings_info.investing_funding_currency_amount}}</span>
              <span style="color: #fff;"> {{all_sales_promotion.funding_currency_code}} </span>
              <span style="color: #fff;"> {{$t('fight_new')}}</span>
            </div>
          </div>

          <div style="float: left; width: 100px; height: 100px; margin: 2.3% 0% 0% 25.5%; background-color: #ffffff; border-radius: 50px;">
            <img :src="all_sales_promotion.currency_icon_url" style="margin: 10px; width: 80px; height: 80px; border-radius: 50px;">
          </div>

          <div style="float: left; margin-left: 15%; margin: 30px 0px 0px 30px"">
            <span style="color: #bbd3ff">{{$t('you_will_receive')}}：</span>
            <div style="margin-top: 45px">
              <span style="font-size: 30px; color: #fff; font-weight: bold">{{all_sales_promotion.user_last_sale_token_fundraisings_info.user_obtain}}</span>
              <span style="color: #fff;">  {{all_sales_promotion.currency_code}} </span>
              <span style="color: #fff;"> ，{{$t('lock_up_period_2_months')}}</span>
            </div>
          </div>
        </div>

        <div style="margin: 5% 0% 5% 0%;">
          <div style="height: 14px; background-color: #f3f4f9; border-radius: 6px;">
            <div style="height: 14px; background: #006ae3; border-radius: 6px; max-width: 100%" :style="{width: all_sales_promotion.progress_percentage}"></div>

          </div>
          <div style="width: 96%;">
            <div style="width: 48px; height: 48px; background-color: #ffffff; border-radius: 50px; padding: 0.3%; margin-top: -29px; max-width: 90%;box-shadow: 0 0 17px 10px var(--sales-promotion_success-bgcolor);" :style="{marginLeft: all_sales_promotion.progress_percentage}">
              <div class="float-core"> {{HundredpercentInteger(all_sales_promotion.progress_percentage)}} </div>
            </div>
          </div>

        </div>



        <div>
          <div class="showPre1">
                <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
                    style="margin-left: 0%;">
                    <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active">
                        <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                            <div class="ant-col ant-col-3" style="padding-left: 45px;width: 25%;padding-top: 2px;">{{$t('user')}}</div>
                            <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('amount')}}({{all_sales_promotion.funding_currency_code}})</div>
                            <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('participation_time')}}</div>
                            <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('amount_received')}}({{all_sales_promotion.currency_code}})</div>
                        </div>
                        <div class="ant-row-flex pages-assets-balances-coinlist-trow" style="padding: 0px 15px;" v-for="user_sale_token_fundraising in all_sales_promotion.all_sale_token">
                          <div style="width: 25%">{{user_sale_token_fundraising.user_email}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{user_sale_token_fundraising.funding_currency_amount}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{user_sale_token_fundraising.created_at}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{user_sale_token_fundraising.sale_token_amount}}</div>
                      </div>
                    </div>
                    <div role="tabpanel" aria-hidden="true" class="ant-tabs-tabpane ant-tabs-tabpane-inactive"></div>
                </div>
              </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style type="text/css" scoped>
  .float-core{
    display: -moz-box;
    display: -webkit-box;

    -moz-box-align: center;
    -webkit-box-align: center;

    -moz-box-pack: center;
    -webkit-box-pack: center;
    width: 42px;
    height: 42px;
    background-color: #006ae3;
    border-radius: 50px;
    color: #ffffff;
    font-size: 6px;
  }
  .outer-most{
    padding: 30px;
    margin-top: 40px;
    height: auto;
    /*margin: 0px auto; */
    border-radius: 4px;
    width: var(--page-content-width);
    /*background-color: var(--assets-new-box-bg);*/
  }
  .first-built-in{
    background-color: var(--assets-new-box-bg);
    width: 100%;
    height: 100%;
    /*border: solid 1px #fdecb1;*/
    /*font-size: 14px;*/
    /*padding: 13px 30px*/
  }
  .img{
    width: 30px;
    height: 30px;
  }
  .margin-size-20{
    margin-top: 20px;
  }
  .margin-size-30{
    margin-top: 30px;
  }
  .float-left{
    float: left;
  }
  .float-right{
    float: right;
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  data () {
    return {
      all_sales_promotion: ""
    }
  },
  watch: {
  },
  created(){
  },
  computed: {

  },
  mounted() {
    this.salesPromotionSuccessfully(this.$route.params.id);
  },
  methods: {
    HundredpercentInteger(data){
      if(data === '100.0%'){
        return '100%'
      }else{
        return data
      }
    },
    salesPromotionSuccessfully(id){
      this.getUrl('/www/sales_promotion/get_all_sales_promotion?id=' + id).then((response)=>{
        if (response.data.code ===  20000) {
          console.log(id)
          console.log(response.data.all_sales_promotion)
          this.all_sales_promotion = response.data.all_sales_promotion;
          // this.myEchart(this.sale_promotion);
        }
      })
    }
  }
}

</script>
