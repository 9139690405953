<template>
	<div class="pages-home-trades-title">{{$t("quote_center")}}</div>
</template>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String
  }
}
</script>