<template>
<section class="pages-ucenter-profile-index-item">
    <section class="pages-ucenter-profile-index-content pages-ucenter-profile-features-features">
        <div class="pages-ucenter-profile-features-feature bind_mailbox">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('bind_mailbox')">
                        <b>{{$t("bind_mailbox")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="email_is_bind == 'bind'">
                        {{$t("bind")}}
                    </div>
                    <div class="features_status features_no_make" v-if="email_is_bind == 'unbound'">
                        {{$t("unbound")}}
                    </div>
                    <div class="features_msg" :title="mailbox_title"  v-if="email_is_bind == 'bind'">
                        {{$t("the_mailbox_you_are_bound_to_is")}}：<span class="features_email">{{bind_email}}</span>
                    </div>
                    <div class="features_msg" :title="$t('you_ave_not_bound_your_mailbox')" v-if="email_is_bind == 'unbound'" style="color: #a5a5ba">
                        {{$t('you_ave_not_bound_your_mailbox')}}
                    </div>

                    <div class="features_button  button_features_make">
                        <router-link to="/bind_email">
                            <div :title="$t('click_edit')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
         <div class="pages-ucenter-profile-features-feature authentication">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('authentication')">
                        <b>{{$t("authentication")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="auth_status == 1">
                        {{$t("verified")}}
                    </div>
                    <div class="features_status features_no_make" v-if="auth_status == 2">
                        {{$t("not_certified")}}
                    </div>
                    <div class="features_status features_no_make" v-if="auth_status == 3" style="color: #ffbe45">
                        {{$t("under_review")}}
                    </div>
                    <div class="features_status features_no_make" v-if="auth_status == 4">
                        {{$t("authentication_failed")}}
                    </div>
                    <!-- <div class="features_msg" :title="id_number_title" v-if="auth_status == 1">
                        {{$t("the_id_number_you_bind_is")}}：<span class="features_email">{{bind_id_card}}</span>
                    </div>
                    <div class="features_msg"  :title="$t('you_have_not_tied_your_id_passport_information')" v-if="auth_status == 2" style="color: #a5a5ba">
                        {{$t('you_have_not_tied_your_id_passport_information')}}
                    </div>
                    <div class="features_msg" :title="$t('certification_please_wait_for_review')"  v-if="auth_status == 3" style="color: #a5a5ba">
                        {{$t('certification_please_wait_for_review')}}
                    </div>
                    <div class="features_msg"  :title="auth_reason" v-if="auth_status == 4" style="color: var(--features-no-make-color)">
                        {{auth_reason}}
                    </div> -->
                    <!-- <div class="features_button  " :class="[auth_status == 2 || auth_status == 4 ? 'button_features_make' : 'button_features_no_make']">
                        <router-link to="/identity_auth" v-if="auth_status == 2 || auth_status == 4" >
                            <div :title="$t('click_edit')" >
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                        <router-link to="#" v-if="auth_status == 1 || auth_status == 3">
                            <div :title="$t('unchangeable')" >
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2047/no_make.png" />{{$t('unchangeable')}}
                            </div>
                        </router-link>
                    </div> -->
                    <div class="features_msg"  :title="$t('please_perform_identity_authentication_in_the_app')">
                        {{$t('please_perform_identity_authentication_in_the_app')}}
                    </div>
                    <div class="features_button  button_features_no_make">
                        <router-link to="#">
                            <div :title="$t('unchangeable')" >
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2047/no_make.png" />{{$t('unchangeable')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature binding_phone">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('binding_phone')">
                        <b>{{$t("binding_phone")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="mobile_is_bind">
                        {{$t("bind")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!mobile_is_bind">
                        {{$t("unbound")}}
                    </div>
                    <div class="features_msg" :title="phone_number_title" v-if="mobile_is_bind">
                        {{$t("the_phone_number_you_bind_is")}}：<span class="features_email">{{bind_mobile}}</span>
                    </div>
                    <div class="features_msg" :title="$t('you_have_not_tied_your_phone_number')" v-if="!mobile_is_bind" style="color: #a5a5ba">
                        {{$t('you_have_not_tied_your_phone_number')}}
                    </div>
                    <div class="features_button button_features_make">
                        <router-link to="/bind_phone">
                            <div :title="$t('click_edit')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature login_password">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('login_password')">
                        <b>{{$t("login_password")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="encrypted_password_is_bind">
                        {{$t("set_up")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!encrypted_password_is_bind">
                        {{$t("not_set_up")}}
                    </div>
                    <div class="features_msg"  :title="$t('use_when_logging_in')" v-if="encrypted_password_is_bind">
                        {{$t("use_when_logging_in")}}
                    </div>
                    <div class="features_msg" :title="$t('you_have_not_set_a_login_password')" v-if="!encrypted_password_is_bind" style="color: #a5a5ba">
                        {{$t('you_have_not_set_a_login_password')}}
                    </div>
                    <div class="features_button button_features_make">
                        <router-link to="/edit_password">
                            <div :title="$t('click_edit')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature fund_password">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('fund_password')">
                        <b>{{$t("fund_password")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="bind_assets_password">
                        {{$t("set_up")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!bind_assets_password">
                        {{$t("not_set_up")}}
                    </div>
                    <div>
                      <div class="features_msg"  :title="$t('use_when_funds_change')" v-if="bind_assets_password && currentUserInfo.fund_password_normal">
                        {{$t("use_when_funds_change")}}
                      </div>
                      <div class="features_msg" :title="$t('you_have_not_set_a_fund_password')"  v-if="!bind_assets_password && currentUserInfo.fund_password_normal" style="color: #a5a5ba">
                          {{$t('you_have_not_set_a_fund_password')}}
                      </div>
                    </div>
                    <div v-if="!currentUserInfo.fund_password_normal">
                      <div class="features_msg"  :title="$t('use_when_funds_change')" v-if="bind_assets_password" style="color: red;">
                        {{$t("fund_password_locked")}}
                      </div>
                    </div>
                    <div class="features_button button_features_make" v-if="currentUserInfo.fund_password_normal">
                      <router-link to="/fund_password">
                        <div :title="$t('click_edit')">
                          <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                        </div>
                      </router-link>
                    </div>
                    <div class="features_button button_features_make"  v-if="!currentUserInfo.fund_password_normal">
                      <div :title="$t('unlocked')" style="cursor:pointer" @click="OperationValidation('unlockFundPwd')">
                        <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('unlocked')}}
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature api">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('api')">
                        <b>{{$t("api")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="bind_api_used">
                        {{$t("activated")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!bind_api_used">
                        {{$t("unopened")}}
                    </div>
                    <div class="features_msg"  :title="$t('developer_use')" v-if="bind_api_used">
                        {{$t("developer_use")}}
                    </div>
                    <div class="features_msg" :title="$t('you_have_not_set_up_the_development_api')" v-if="!bind_api_used" style="color: #a5a5ba">
                        {{$t('you_have_not_set_up_the_development_api')}}
                    </div>
                    <div class="features_button button_features_make"  @click="openApi"  v-if="!bind_api_used">
                        <router-link to="#">
                            <div :title="$t('click_to_open')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_to_open')}}
                            </div>
                        </router-link>
                    </div>
                    <div class="features_button button_features_make"  v-if="bind_api_used">
                        <router-link to="/api_management">
                            <div :title="$t('activated')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('activated')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature bind_google_authenticator">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('bind_google_authenticator')">
                        <b>{{$t("bind_google_authenticator")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="bind_otp_binded">
                        {{$t("bind")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!bind_otp_binded">
                        {{$t("unbound")}}
                    </div>
                    <div class="features_msg" :title="$t('can_replace_sms_verification_code_to_make_login_faster_and_safer')" v-if="bind_otp_binded">
                        {{$t("can_replace_sms_verification_code_to_make_login_faster_and_safer")}}
                    </div>
                    <div class="features_msg"  :title="$t('you_have_not_set_up_bind_google_validator')" v-if="!bind_otp_binded" style="color: #a5a5ba">
                        {{$t('you_have_not_set_up_bind_google_validator')}}
                    </div>
                    <div class="features_button button_features_make">
                        <router-link to="/google_auth">
                            <div :title="$t('click_edit')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-profile-features-feature otc_nickname">
            <div class="pages-ucenter-profile-features-info">
                <div class="features_box_text bind_mailbox_text">
                    <div class="features_title" :title = "$t('otc_trading_nickname')">
                        <b>{{$t("otc_trading_nickname")}}</b>
                    </div>
                    <div class="features_status features_make" v-if="bind_otc_nickname">
                        {{$t("set_up")}}
                    </div>
                    <div class="features_status features_no_make" v-if="!bind_otc_nickname">
                        {{$t("not_set_up")}}
                    </div>
                    <div class="features_msg" :title="$t('can_replace_sms_verification_code_to_make_login_faster_and_safer')" v-if="bind_otc_nickname">
                        {{$t("your_otc_transaction_nickname_is")}}: {{otc_nickname}}
                    </div>
                    <div class="features_msg"  :title="$t('you_have_not_set_an_otc_trading_nickname')" v-if="!bind_otc_nickname" style="color: #a5a5ba">
                        {{$t('you_have_not_set_an_otc_trading_nickname')}}
                    </div>
                    <div class="features_button button_features_make">
                        <router-link to="/otc_nickname">
                            <div :title="$t('click_edit')">
                                <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2046/make.png" />{{$t('click_edit')}}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="invitation_card">
        <div>
            <img :src="invitation_card_img" />
        </div>
        <div class="invitation_text">
            {{$t('your_invitation_code_is')}}
            <span class="invitation_code">{{invitation_code}}</span>
            ,{{$t('you_can_use_this_invitation_code_to_invite_friends_to_register_on_this_site_when_registering_let_the_other_party_fill_in_the_invitation_code_there_are_surprises_in_the_invitation')}}~<br>
            {{$t('the_number_of_people_i_invited')}}: {{invitation_code_count}}
            <span class="invitation_code_look" style="margin-left: 30px;">
                <router-link to="/invite_qrcode">{{$t('view')}}</router-link>
            </span>
            <span class="invitation_code_look" style="margin-left: 30px;">
                <router-link to="/show_my_family">{{$t('show_all_my_family')}}</router-link>
            </span>
        </div>
    </div>

<operation-validation/>
</section>
</template>
<style type="text/css" scoped>
    .features_title{
        font-size: 20px;
        color: #1b1926;
        padding-left: 100px;
        padding-top: 21px;
        color: var(--header-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .features_status{
        font-size: 16px;
        margin-left: 122px;
        display:list-item;
        list-style-type:disc;
    }
    .features_no_make{
        color: var(--features-no-make-color);
    }
    .features_make{
        color: var(--features-make-color);
    }
    .features_msg{
        font-size: 16px;
        color: var(--features-msg-color);
        padding-top: 30px;
        padding-left: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 58px;
    }
    .features_box_text{
        display: flex;
        flex-direction:row;
        flex-direction: column;
        align-content: flex-start;
        line-height: 28px;
        width: 100%;
    }
    .button_features_no_make{
        background-color: var(--features-msg-button-bgcolor);
    }
    .button_features_make a div{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .button_features_make{
        background-color: #006cdb;
    }
    .button_features_make div{
        color: #ffffff;
    }
    .button_features_no_make div{
        color: #a5a5ba;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .features_button{
        width: 104px;
        height: 28px;
        border-radius: 14px;
        text-align: center;
        margin-left: 260px;
        margin-top: 32px;
        padding-right: 7px;
    }
    .features_email{
        color: #006cdb
    }
    .invitation_code_look{
        color: #006cdb;
    }
    .invitation_code{
        color: #006cdb;
    }
    .invitation_text{
        color: var(--invitation-text-color);
        margin-left: 10px;
    }
    .invitation_card{
        display: flex;
        width: 927px;
    }
    .bind_mailbox{
        background:var(--user-center-email-bgimg);
    }
    .authentication{
        background:var(--user-center-auth-bgimg);
    }
    .binding_phone{
        background:var(--user-center-phone-bgimg);
    }
    .login_password{
        background:var(--user-center-loginpwd-bgimg);
    }
    .fund_password{
        background:var(--user-center-fundpwd-bgimg);
    }
    .api{
        background:var(--user-center-api-bgimg);
    }
    .bind_mailbox{
        background:var(--user-center-email-bgimg);
    }
    .bind_google_authenticator{
        background:var(--user-center-google-bgimg);
    }
    .otc_nickname{
        background:var(--user-center-otcnickname-bgimg);
    }
</style>
<script type="text/javascript">
import OperationValidation from '../OperationValidation'
export default {
  components: {
    OperationValidation
  },
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)

  },
  data() {
     return {
        isBdphone:false,
     }
  },
  methods:{
    openApi(){
        this.postUrl('/www/user_center/open_api', {
            lang:this.currentLang
        }).then((response) => {
            if(response.data.code == 20000){
               //重新加载用户数据
               this.getMemberInfo();
            }
        });
    }
  },
  computed: {
    auth_reason: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.auth_reason
        }else{
            return false;
        }
    },
    invitation_code_count: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.invitation_code_count
        }else{
            return false;
        }
    },
    invitation_code: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.invitation_code
        }else{
            return false;
        }
    },
    bind_otp_binded: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.is_otp_binded
        }else{
            return false;
        }
    },
    otc_nickname: function () {
        return this.currentUserInfo.otc_nickname;
    },
    bind_otc_nickname: function () {
        if(this.isLoggedIn){
            if(this.currentUserInfo.otc_nickname == '' || this.currentUserInfo.otc_nickname == null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    bind_api_used: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.is_api_used
        }else{
            return false;
        }
    },
    bind_assets_password: function () {
        if(this.isLoggedIn){
            if(this.currentUserInfo.is_bind_assets_password == '' || this.currentUserInfo.is_bind_assets_password == null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    encrypted_password_is_bind: function () {
        if(this.isLoggedIn){
            if(this.currentUserInfo.encrypted_password == '' || this.currentUserInfo.encrypted_password == null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    bind_mobile: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.mobile
        }else{
            return '';
        }
    },
    mobile_is_bind: function () {
        if(this.isLoggedIn){
            if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    bind_id_card: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.id_card
        }else{
            return '';
        }
    },
    auth_status: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.auth_status
        }else{
            return false;
        }
    },
    bind_email: function (){
        if(this.isLoggedIn){
            return this.currentUserInfo.email
        }else{
            return '';
        }
    },
    email_is_bind: function (){
        if(this.isLoggedIn){
            if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
                return 'unbound';
            }else{
                return 'bind';
            }
        }else{
            return 'unbound';
        }
    },
    mailbox_title: function (){
        return this.$t('the_mailbox_you_are_bound_to_is') + '：' + this.bind_email;
    },
    id_number_title: function (){
        return this.$t('the_id_number_you_bind_is') + '：' + this.bind_id_card;
    },
    phone_number_title: function (){
        return this.$t('the_phone_number_you_bind_is') + '：' + this.bind_mobile;
    },
    invitation_card_img: function () {
      if(this.currentTheme == 'light'){
        return "https://showmethemoney.fuxinsci.com/image_after_2019_06/2043/invitation_card_white.png";
      }else{
        return "https://showmethemoney.fuxinsci.com/image_after_2019_06/2042/invitation_card_black.png";
      }
    }
  },
  mounted() {
    this.getMemberInfo()
  }
}
</script>
