<template>
<div class="pages-assets-balances-index-header" style="display: block;margin-top: 70px;">
    <div class="pages-assets-balances-index-main" v-if="faceValue != 'transfer'">
        <div class="Asset-header-left" v-if="this.$route.name == 'rwd_assets'">
            <h1>{{$t("asset_overview")}}</h1>
            <div class="pages-assets-balances-index-abs" style="display: flex;">
              <span style="display: block;padding-top: 3px;">{{$t("asset_conversion")}}</span>
              <i class="pages-assets-balances-index-eyes iconfont "  v-on:click="showOrHiddenAssets" v-bind:class="[isshow === true ? 'icon-xianshikejian' : 'icon-yincangbukejian pages-assets-balances-index-eyeActive' ]" style="vertical-align: top;"></i>
            </div>
            <div class="pages-assets-balances-index-convert">{{total_assets}} {{currency.code}}
              <span class="pages-assets-balances-index-cny"> ≈ {{withdrawsFiatSymbol}}{{withdrawsFiatAmount}} {{withdrawsConvertCurrency}}</span>
            </div>
        </div>
        <div class="Asset-header-left" v-if="this.$route.name == 'my_assets'">
            <h1>{{$t("asset_overview")}}</h1>
            <div class="pages-assets-balances-index-abs" style="display: flex;">
              <span style="display: block;padding-top: 3px;">{{$t("total_assets")}}</span>
              <i class="pages-assets-balances-index-eyes iconfont "  v-on:click="showOrHiddenAssets" v-bind:class="[isshow === true ? 'icon-xianshikejian' : 'icon-yincangbukejian pages-assets-balances-index-eyeActive' ]" style="vertical-align: top;"></i>
            </div>
            <div class="pages-assets-balances-index-convert"><!-- {{cg_total_assets}} CG -->
              <span class="pages-assets-balances-index-cny"> ≈ {{fiat_symbol}}{{fiatsCount}} {{convertCurrency}}</span>
            </div>
        </div>
    </div>

    <div class="pages-assets-balances-index-main" v-if="faceValue == 'transfer'">
        <div class="Asset-header-left">
            <h1>{{currency.code}}{{$t("station_transfer")}}</h1>
            <div class="pages-assets-balances-index-abs" style="color: var(--assets-label-color);font-size: 16px;">
              <div>{{$t("transfer_to_the_account_as_soon_as_it_is_initiated")}}</div>
              <div>{{$t("please_confirm_the_email_address_of_the_user_before_the_transfer")}}</div>
              <div>{{$t("the_transfer_operation_is_irreversible")}},{{currentHomeText}}{{$t("is_not_responsible_for_disputes_caused_by_transferring_into_the_wrong_account")}}</div>
            </div>
        </div>
    </div>
</div>
</template>
<style type="text/css"  scoped>
    .pages-assets-balances-index-balance div p{
        color: var(--main-color);
    }
    .pages-assets-balances-index-convert{
        color: var(--main-color);
    }
    .Asset-header-left{
      margin-left: 20px;
    }
    .Asset-header-right{
      margin-right: 370px;
    }
</style>
<script type="text/javascript">
export default {
  props: {
        // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
        faceValue: String,
        currency: Object,
        withdrawAvailableBalance: String,
        withdrawLockedBalance: String,
        withdrawsFiatSymbol: String,
        withdrawsFiatAmount: String,
        withdrawsConvertCurrency: String,
        cgTotal: String,
        fiatsCount: String,
        fiat_symbol: String,
        convertCurrency: String,
        withdrawTotalAmount: String,
        transferTotalAmount: String
  },
   data() {
     return {
        isshow:true,
        the_currency:"0.00000000",
        CNY:"0.00",
        USDT:"0",
        CNYT:"0"
     }
   },
   computed: {
    language: function () {
      return this.currentLang;
    },
    cg_total_assets: function() {
      if(this.isshow == true){
         return this.cgTotal;
      }else if (this.isshow == false){
        return '--';
      }
    },
    total_assets: function () {
      if(this.isshow == true){
         return this.withdrawTotalAmount
      }else if (this.isshow == false){
        return '--';
      }
    }
  },
  mounted() {

  },
   methods: {
      showOrHiddenAssets() {
        this.isshow = !this.isshow;
        if(this.isshow == true){
            this.CNY = "0.00";
            this.USDT = "0";
            this.CNYT = "0";
        }else if (this.isshow == false){
            this.CNY = "--";
            this.USDT = "--";
            this.CNYT = "--";
        }
    }
  }
}
</script>
