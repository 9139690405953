<template>
<section class="pages-ucenter-commonform-content">
  <div class="redio_type" v-if="is_show_unbind">
    <div v-for="item in radioData" :style="{'margin-left': item.label == 'unbind' ? '40px' : '0px'}">
      {{item.name}}&nbsp;<img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2052/redio_no_checkd.png" v-if="item.label != checkTypeVal" @click="checkRedio(item.label)" style="margin-bottom: 4px;">
      <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2051/redio_checkd.png" v-if="item.label == checkTypeVal" style="margin-bottom: 4px;">
    </div>
  </div>
  <div v-if="mobile_is_bind" class="now_mobile_msg">{{$t('the_phone_number_you_bind_is')}}：{{now_mobile}}</div>
  <form class="ant-form ant-form-vertical ant-form-hide-required-mark"  v-if="checkTypeVal == 'bind'">
    <!-- <div class="pages-ucenter-bind-phone-index-captcha" v-if="mobile_is_bind">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorMobileMsmClassOld ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('original_phone_verification_code')">{{$t("original_phone_verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorMobileMsmClassOld ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="smsCode" class="ant-input ant-input-lg" v-model="mobileMsmOld" @keyup="inputMobileMsmOld()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="phoneMsmIsAbleOld" @click="phoneMsmSendOld" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px">
                      <span style="font-size: 14px;">{{phoneMsmButtonOld}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorMobileMsmDivOld">{{msmErrorMobileMsg}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="ant-row ant-form-item" v-bind:class="[isShowErrorMobileClass ? 'ant-form-item-with-help' : null ]">
      <div class="ant-col ant-form-item-label">
        <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_phone_you_want_to_bind')"  v-if="!mobile_is_bind">{{$t("please_enter_the_phone_you_want_to_bind")}}</label>
        <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_mobile_phone_you_want_to_change')"  v-if="mobile_is_bind">{{$t("please_enter_the_mobile_phone_you_want_to_change")}}</label>
      </div>
      <div class="ant-col ant-form-item-control-wrapper">
        <div class="ant-form-item-control"  v-bind:class="[isShowErrorMobileClass ? 'has-error' : null ]">
          <span class="ant-form-item-children">
            <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
              <span class="ant-input-wrapper ant-input-group">
                <span class="ant-input-group-addon">
                  <div class="components-countryselect-index-wrapper">
                    <div class="ant-select-lg ant-select ant-select-enabled" style="min-width: 100px; max-width: 200px;border: solid 0px var(--bind-phone-phone-bodercolor);">
                      <div class="ant-select-selection
                      ant-select-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-controls="27325a7f-b634-4deb-ac8d-61c3d7c19d6e" aria-expanded="false" tabindex="0" @click="$emit('show-country',isHideCountry)" @click.stop="hideAlert($event)">
                        <div class="ant-select-selection__rendered" style="margin-left: 30px;">
                          <div class="ant-select-selection-selected-value" title="" style="display: block; opacity: 1;">
                            <!-- <span class="components-countryselect-index-flag " :class="defaultCountryIconClass"></span> -->
                            <span class="components-countryselect-index-code">{{defaultCountryMember}}</span>
                          </div>
                          <div class="ant-select-search ant-select-search--inline" style="display: none;">
                            <div class="ant-select-search__field__wrap">
                              <input autocomplete="off" class="ant-select-search__field" value="">
                              <span class="ant-select-search__field__mirror">&nbsp;</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <input type="text" id="mobile" class="ant-input ant-input-lg" v-model="mobile" @keyup="inputMobile()" maxlength="11"></span>
            </span>
          </span>
          <div class="ant-form-explain" v-if="isShowErrorMobileDiv">{{mobileErrorMsg}}</div>
        </div>
      </div>
    </div>
    <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorMobileMsmClass ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('verification_code')">{{$t("verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorMobileMsmClass ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="smsCode" class="ant-input ant-input-lg" v-model="mobileMsm" @keyup="inputMobileMsm()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="phoneMsmIsAble" @click="phoneMsmSend" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px">
                      <span style="font-size: 14px;">{{phoneMsmButton}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorMobileMsmDiv">{{msmErrorMobileMsg}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pages-ucenter-commonform-handle">
      <button type="button" class="ant-btn ant-btn-primary" style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="submitIsAble" :title="$t('confirm_binding')" @click="submitBindPhoneValidation">
        <span>{{$t("confirm_binding")}}</span>
      </button>
      <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
    </div>
  </form>

  <form class="ant-form ant-form-vertical ant-form-hide-required-mark"   v-if="checkTypeVal == 'unbind' && is_show_unbind">
    <!-- <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorMobileMsmClassUnbind ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('verification_code')">{{$t("verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorMobileMsmClassUnbind ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="smsCode" class="ant-input ant-input-lg" v-model="mobileMsmUnbind" @keyup="inputMobileMsmUnbind()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="phoneMsmIsAbleUnbind" @click="phoneMsmSendUnbind" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px">
                      <span style="font-size: 14px;">{{phoneMsmButtonUnbind}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorMobileMsmDivUnbind">{{msmErrorMobileMsgUnbind}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="pages-ucenter-commonform-handle">
      <button type="button" class="ant-btn ant-btn-primary" style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :title="$t('unbind')"  @click="OperationValidation('unbindMobile')">
        <span>{{$t("unbind")}}</span>
      </button>
      <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
    </div>
  </form>
</section>
</template>
<style type="text/css">
  .redio_type{
    display: flex;
    justify-content:flex-start;
    font-size: 20px;
    margin-bottom: 30px;
    color: var(--main-color);
  }
  .redio_type div input{
    width: 20px;
    height: 18px;
  }
  .now_mobile_msg{
    color: #e45858;
    margin-bottom: 15px;
    font-size: 13px;
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
import i18n from '../../i18n/i18n'
import store from '../../vuex/store'
export default {
  props: {
      isHideCountry: Boolean,
      defaultCountryIconClass:String,
      defaultCountryMember:String,
      isHideLanguage: Boolean
  },
  data() {
     return {
      mobile: '',
      mobileMsm: '',
      mobileMsmOld: '',
      mobileMsmUnbind: '',
      phoneMsmIsAble:true,
      phoneMsmIsAbleUnbind:false,
      countrys: [
          {
              name:"中国",
              number:"+86",
              icon:"components-countryselect-index-flag-cn"
          },
          {
              name:"中国香港",
              number:"+852",
              icon:"components-countryselect-index-flag-hk",
          },
          {
              name:"安道尔",
              number:"+376",
              icon:"components-countryselect-index-flag-ad",
          },
          {
              name:"美属萨摩亚",
              number:"+1684",
              icon:"components-countryselect-index-flag-as",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          },
          {
              name:"阿尔及利亚",
              number:"+213",
              icon:"components-countryselect-index-flag-dz",
          }
      ],
      isShowErrorMobileClass:false,
      isShowErrorMobileDiv:false,
      mobileErrorMsg:"",
      isShowErrorMobileMsmClass:false,
      isShowErrorMobileMsmDiv:false,
      isShowErrorMobileMsmClassOld:false,
      isShowErrorMobileMsmDivOld:false,
      isShowErrorMobileMsmClassUnbind:false,
      isShowErrorMobileMsmDivUnbind:false,
      msmErrorMobileMsg:"",
      msmErrorMobileMsgUnbind:"",
      phoneMsmCd:60,
      phoneMsmCdUnbind:60,
      checkTypeVal: 'bind',
      bindMsmIsSend: false,
      unBindMsmIsSend: false,
      phoneMsmCdOld:60,
      bindMsmIsSendOld: false,
      phoneMsmIsAbleOld:false
     }
  },
  computed: {
    mobile_is_bind: function (){
      if(this.currentJudgeIsLoggedIn){
           if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
              return false;
          }else{
              return true;
          }
      }else{
          return false;
      }
    },
    now_mobile: function () {
      if(this.currentJudgeIsLoggedIn){
          return this.currentUserInfo.mobile;
      }else{
          return '';
      }
    },
    is_show_unbind: function (){
        if(this.currentJudgeIsLoggedIn){
            if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
                return false;
            }else{
                if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
                  return false;
                }else{
                  return true;
                }
            }
        }else{
            return false;
        }
    },
    radioData: function (){
      return [
        {
          label:'bind',
          name: this.$t('replace_bound_phone')
        },
        {
          label:'unbind',
          name: this.$t('unbind')
        }
      ]
    },
    submitIsAble: function () {
      if(this.mobile_is_bind){
          if(!this.isShowErrorMobileClass && !this.isShowErrorMobileDiv && !this.isShowErrorMobileMsmClass && !this.isShowErrorMobileMsmDiv && this.mobile != '' && this.mobileMsm != ''){
            return false;
        }else{
            return true;
        }
      }else{
        if(!this.isShowErrorMobileClass && !this.isShowErrorMobileDiv && !this.isShowErrorMobileMsmClass && !this.isShowErrorMobileMsmDiv && this.mobile != '' && this.mobileMsm != ''){
              return false;
          }else{
              return true;
          }
      }
    },
    submitIsAbleUnbind: function () {
      if(!this.isShowErrorMobileMsmClassUnbind && !this.isShowErrorMobileMsmDivUnbind && this.mobileMsmUnbind != ''){
          return false;
      }else{
          return true;
      }
    },
    phoneMsmButton: function () {
       if(this.phoneMsmCd > 0 && this.phoneMsmIsAble == true && this.isShowErrorMobileClass == false && this.isShowErrorMobileDiv == false && this.mobile != '' && this.bindMsmIsSend){
           return "("+(this.phoneMsmCd )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    phoneMsmButtonOld: function () {
       if(this.phoneMsmCdOld > 0 && this.phoneMsmIsAbleOld == true && this.bindMsmIsSendOld){
           return "("+(this.phoneMsmCdOld )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    phoneMsmButtonUnbind: function () {
       if(this.phoneMsmCdUnbind > 0 && this.phoneMsmIsAbleUnbind == true && this.unBindMsmIsSend){
           return "("+(this.phoneMsmCdUnbind )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    }
  },
  methods: {
    checkRedio(radio_label){
      this.checkTypeVal = radio_label;
    },
    submitBindPhoneValidation(){
      this.postUrl('/www/user_center/valid_new_phone', {
          lang:this.currentLang,
          mobile: this.mobile,
          mobile_code: this.mobileMsm
       }).then((response) => {
          if(response.data.code == 20000){
            Vue.setGlobalVariable({
              'new_phone': this.mobile
            });
            // 新手机号验证通过后开始热操作验证
            this.OperationValidation("bindMobile");
          }
       });
    },

    submitBindPhoneUnbind(){
      this.postUrl('/www/user_center/unbind_mobile', {
          lang:this.currentLang,
          mobile_code: this.mobileMsmUnbind
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },
    inputMobileMsmUnbind(){
      this.mobileMsmUnbind = this.mobileMsmUnbind.replace(/[^0-9]/g,'');
      if (this.mobileMsmUnbind == '') {
          //不合法 不可为空
          this.msmErrorMobileMsgUnbind = this.$t('please_enter_sms_verification_code');
          this.isShowErrorMobileMsmClassUnbind = true;
          this.isShowErrorMobileMsmDivUnbind = true;
      }else if(this.mobileMsmUnbind.length != 6){
          this.msmErrorMobileMsgUnbind = this.$t('sms_verificationcode_format_is_incorrect');
          this.isShowErrorMobileMsmClassUnbind = true;
          this.isShowErrorMobileMsmDivUnbind = true;
      }else{
          //正确
          this.isShowErrorMobileMsmClassUnbind = false;
          this.isShowErrorMobileMsmDivUnbind = false;
      }
    },
    inputMobileMsm(){
      this.mobileMsm = this.mobileMsm.replace(/[^0-9]/g,'');
      if (this.mobileMsm == '') {
          //不合法 不可为空
          this.msmErrorMobileMsg = this.$t('please_enter_sms_verification_code');
          this.isShowErrorMobileMsmClass = true;
          this.isShowErrorMobileMsmDiv = true;
      }else if(this.mobileMsm.length != 6){
          this.msmErrorMobileMsg = this.$t('sms_verificationcode_format_is_incorrect');
          this.isShowErrorMobileMsmClass = true;
          this.isShowErrorMobileMsmDiv = true;
      }else{
          //正确
          this.isShowErrorMobileMsmClass = false;
          this.isShowErrorMobileMsmDiv = false;
      }
    },
    inputMobileMsmOld(){
      this.mobileMsmOld = this.mobileMsmOld.replace(/[^0-9]/g,'');
      if (this.mobileMsmOld == '') {
          //不合法 不可为空
          this.msmErrorMobileMsgOld = this.$t('please_enter_sms_verification_code');
          this.isShowErrorMobileMsmClassOld = true;
          this.isShowErrorMobileMsmDivOld = true;
      }else if(this.mobileMsmOld.length != 6){
          this.msmErrorMobileMsgOld = this.$t('sms_verificationcode_format_is_incorrect');
          this.isShowErrorMobileMsmClassOld = true;
          this.isShowErrorMobileMsmDivOld = true;
      }else{
          //正确
          this.isShowErrorMobileMsmClassOld = false;
          this.isShowErrorMobileMsmDivOld = false;
      }
    },
    phoneMsmSend: function () {
      this.phoneMsmIsAble = true;
      //发送手机验证码
       this.postUrl('/www/user_center/send_bind_phone_code', {
          lang:this.currentLang,
          mobile: this.mobile
       }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.bindMsmIsSend = true;
            this.timer1 = setInterval( () => {
              if(this.phoneMsmCd > 1){
                 this.phoneMsmIsAble = true;
                 this.phoneMsmCd = this.phoneMsmCd - 1;
             }else{
                 //重置
                 this.phoneMsmIsAble = false;
                 this.phoneMsmCd = 60;
                 this.bindMsmIsSend = false;
                 clearInterval(this.timer1)
             }
    　　　　}, 1000);
          }else{
            this.phoneMsmIsAble = false;
          }
       });
　　},
    phoneMsmSendOld: function () {
      this.phoneMsmIsAbleOld = true;
      //发送手机验证码
       this.postUrl('/www/user_center/send_bind_old_phone_code', {
          lang:this.currentLang
       }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.bindMsmIsSendOld = true;
            this.timer1Old = setInterval( () => {
              if(this.phoneMsmCdOld > 1){
                 this.phoneMsmIsAbleOld = true;
                 this.phoneMsmCdOld = this.phoneMsmCdOld - 1;
             }else{
                 //重置
                 this.phoneMsmIsAbleOld = false;
                 this.phoneMsmCdOld = 60;
                 this.bindMsmIsSendOld = false;
                 clearInterval(this.timer1Old)
             }
    　　　　}, 1000);
          }else{
            this.phoneMsmIsAbleOld = false;
          }
       });
　　},
    phoneMsmSendUnbind: function () {
      this.phoneMsmIsAbleUnbind = true;
      this.postUrl('/www/user_center/send_unbind_mobile_code', {
          lang:this.currentLang
       }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.unBindMsmIsSend = true;
            this.timer2 = setInterval( () => {
              if(this.phoneMsmCdUnbind > 1){
                 this.phoneMsmIsAbleUnbind = true;
                 this.phoneMsmCdUnbind = this.phoneMsmCdUnbind - 1;
              }else{
                 //重置
                 this.phoneMsmIsAbleUnbind = false;
                 this.phoneMsmCdUnbind = 60;
                 this.unBindMsmIsSend = false;
                 clearInterval(this.timer2)
              }
    　　　　 }, 1000);
          }else{
            this.phoneMsmIsAbleUnbind = false;
          }
       });
　　},
    inputMobile(){
      this.mobile = this.mobile.replace(/[^0-9]/g,'');
      let reg = /^1[0-9]{10}$/
      if (this.mobile == '') {
          //不合法 不可为空
          this.mobileErrorMsg = this.$t('please_enter_phone_number');
          this.isShowErrorMobileClass = true;
          this.isShowErrorMobileDiv = true;
          this.phoneMsmIsAble = true;
      }else if(this.mobile.length <= 10 || !reg.test(this.mobile)){
          //不合法 格式错误
          this.mobileErrorMsg = this.$t('phone_number_format_is_incorrect');
          this.isShowErrorMobileClass = true;
          this.isShowErrorMobileDiv = true;
          this.phoneMsmIsAble = true;
      }else{
          //正确
          this.isShowErrorMobileClass = false;
          this.isShowErrorMobileDiv = false;
          this.phoneMsmIsAble = false;
      }
    },
    hideAlert(){

    }
  }
}
</script>
