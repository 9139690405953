<template>
	<div class="pages-home-index-welcome">
        <div class="pages-home-index-logo">
            <label :for="currentHomeText">{{currentHomeText}} {{$t("digital_ssset_service_platform")}}</label>
        </div>
        <div class="pages-home-index-slogan">{{$t("the_world_is_leading_digital_asset_service_platform")}}</div>
        <div class="pages-home-index-small">
            {{$t("protect_your_digital_assets")}}
        </div>
        <div class="pages-home-swipers-wrapper" style="height: 190px;">
            <swiper :options="swiperOption" v-if="sliderImagesData.length > 0 || activityBannerImgUrl != ''">

                <swiper-slide class="swiper-slide" v-if="activityBannerImgUrl != ''">
                    <router-link to="/">
                        <div class="swiper-slide pages-home-swipers-grid" :style="'background-size: cover;background-position: 50% center;border-radius: 11px;height: 190px;background-image: url('+activityBannerImgUrl+')'">
                        </div>
                    </router-link>
                </swiper-slide>

                <swiper-slide class="swiper-slide" v-for="item in sliderImagesData">
                    <div class="swiper-slide pages-home-swipers-grid" style="height: 200px;">
                        <div class="pages-home-swipers-grid " v-for="item_result in item">
                            <a :href="item_result.link_url" target="_blank" rel="noopener noreferrer">
                                <div :style="'background-image: url('+ item_result.url +');background-size: cover;background-position: 50% center;border-radius: 11px;'" :title="item_result.title">
                                </div>
                            </a>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="pages-home-pair-wrapper">
            <div class="pages-home-pair-main tblist">
                <div class="ant-row" style="display: flex;height: 100%;">
                    <div class="ant-col pages-home-pair-item market_data" style="flex: 1;"  v-for="(item,i) in markets" @click="goTrading(item.id)">
                        <div class="pages-home-pair-info">
                            <div class="pages-home-pair-left">
                                <p style="color: #f7fdff">{{item.ask_unit_upcase}}/{{item.bid_unit_upcase}}</p>
                                <p style="color: #09b3ff;">{{item.last_price}}</p>
                            </div>
                            <div class="pages-home-pair-right " :style="{'color': item.price_change_ratio.indexOf('-') == -1 ? 'var(--rise-color)' : 'var(--down-color)'}">{{item.price_change_ratio}}</div>
                        </div>
                        <div style="height: 92px; -webkit-tap-highlight-color: transparent; user-select: none;cursor:pointer;">
                            <div style="position: relative; overflow: hidden; width: auto;padding: 0px; margin: 0px; border-width: 0px;">
                                <div :id="'myChart'+i" class="chart" :ref="'myChart'+i" style="cursor:pointer;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
    @keyframes dong {
        0% {
           transform: translate(0px, 0px);
        }
        50% {
           transform: translate(0px, -20px);
        }
        100% {
           transform: translate(0px, 0px);
        }
    }
    .pages-home-index-logo{
        animation: dong 4s infinite;
    }
    .pages-home-index-slogan{
        animation: dong 4s infinite;
    }
    .pages-home-index-small{
        animation: dong 4s infinite;
    }
    .market_data:hover
    {
        cursor: pointer;
        background-color:#775c9157;
    }
    .tblist{
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .tblist::-webkit-scrollbar {
        /*高宽分别对应横竖滚动条的尺寸*/
        width: 5px;
        height: 7px;
    }
    /*滚动条里面小方块*/
    .tblist::-webkit-scrollbar-thumb {
      border-radius: 2.5px;
      background-color: #ccc;
    }
    /*滚动条里面轨道*/
    .tblist::-webkit-scrollbar-track {
      background-color: #fff;
    }
    .ant-col.pages-home-pair-item:not(:last-child):after {
        content: ' ';
        position: absolute;
        width: 1px;
        height: 95px;
        opacity: 0.4;
        background-color: #775c91;
        top: -3px;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .chart{
        width: auto;
        min-width: 180px;
        height: 70px;
        cursor:pointer;
    }
    .pages-home-pair-item-spacing{
        width: 2px;
        height: 123px;
        background-color: #09b3ff;
        opacity: 0.4;
        margin-top: 17px;
    }
    .commodity{
        background: #f5f5f5;
        .container{
            position: relative;
            .swiText {
                position: absolute;
                height: 0.5rem;
                width: 0.5rem;
                bottom: 0.2rem;
                right: 0.3rem;
                font-size: 0.33rem;
                color: #fff;
                z-index: 10;
            }
            .swiImgs {
                width: 100%;
                .item{
                    height: 7.5rem;
                    .swiImg {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
<script type="text/javascript">
import Vue from 'vue'
import echarts from 'echarts'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String,
    sliderImagesData: Array,
    markets: Array,
    activityBannerImgUrl: String
  },
  created() {

  },
  data() {
    const that = this;
    return {
        //设置属性
        swiperOption: {
            //开启循环模式
            loop: true,
            //设定初始化时slide的索引
            initialSlide: 0,
            //开启鼠标滚轮控制Swiper切换
            //mousewheel: true,
            //滑动速度
            speed: 800,
            //滑动方向
            direction: "vertical",
            //小手掌抓取滑动
            grabCursor: true,
            //显示分页
            pagination: {
              el: '.swiper-pagination',
              clickable: true, //允许分页点击跳转
              type: "bullets"
            },
            //设置点击箭头
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            //自动轮播
            autoplay: {
              delay: 3000,
              stopOnLastSlide: false,
              disableOnInteraction: false
            },
            //滑动之后回调函数
            on: {
                slideChangeTransitionStart: function() {
                    that.imgIndex = this.realIndex + 1; 
                    //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
                },
            }
        }
    }
  },
  watch: {
    currentWwwHomeMarket(newVal) {
        if (newVal.length === 0){
            return
        }
        this.$emit('reload-markets')
    },
    markets(newVal) {
        if (newVal.length === 0){
            return
        }
        // 调用绘制图表
        this.draw();
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    goTrading(market_id){
        //到达trading币种页
        window.open(Vue.getValueByKey('trading_path')+ '/exchange/' + market_id + '?lang=' + this.currentLang, "_blank");
    },
    draw () {
         this.$nextTick(()=>{
            for (var i = 0; i < this.markets.length; i++) {
                var temp_tb = [];
                for (var k = 0; k < this.markets[i]['trend_data'].length; k++) {
                    temp_tb.push(this.markets[i]['trend_data'][k]['price']);
                }
                // 实例化echarts对象
                myChart = 'myChart'+i;
                myChart = echarts.init(this.$refs[myChart][0]);
                //图表更改，重新绘制
                myChart.resize();
                // 绘制条形图
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        show:false
                    },
                    yAxis: {
                        type: 'value',
                        min: Math.min.apply(null, temp_tb),
                        max: Math.max.apply(null, temp_tb),
                        show:false,
                    },
                    series: [{
                        cursor: "pointer",
                        data: temp_tb,
                        type: 'line',
                        smooth: true,
                        showSymbol: false,//不显示标点
                        itemStyle: {
                          normal: {
                            color:'#2ec177',//改变折线点颜色
                            lineStyle:{
                              color: '#2ec177', //改变线条颜色
                            }
                          }
                        },
                        lineStyle:{
                          normal: {
                            width: 1.6
                          }
                        }
                    }],
                    grid: {
                        x: 0,
                        x2: 0,
                        y: 40
                    },
                },true)
            }
        })
    }
  },
  mounted() {
    let that = this
    setTimeout(function(){
      console.info("== this.props.sliderImagesData:", that.sliderImagesData )
      console.info("== this.props.activityBannerImgUrl:", that.activityBannerImgUrl )
    }, 4000)
  },
  computed: {
  }
}
</script>
