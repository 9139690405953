<template>
  <div class="pages-home-trades-main" style="margin-bottom: 50px">
    <div class="pages-home-trades-tabs" style="margin-top: 50px;">
      <div class="ant-row" style="color: var(--main-color);">
        <span class="hand" style="margin-right: 30px;" :class="['ongoing' === currentSalesPromotion ? 'font-color' : null ]" @click="SelectTab('ongoing')">{{$t('inprogress')}}</span>
        <span class="hand tab-spacing" :class="['upcoming' === currentSalesPromotion ? 'font-color' : null ]" @click="SelectTab('upcoming')">{{$t('coming')}}</span>
        <span class="hand tab-spacing" :class="['hasended' === currentSalesPromotion ? 'font-color' : null ]" @click="SelectTab('hasended')">{{$t('finished')}}</span>
        <span class="hand tab-spacing" :class="['myfightnew' === currentSalesPromotion ? 'font-color' : null ]" @click="SelectTab('myfightnew')" v-if="isLoggedIn">{{$t('check_my_updates')}}</span>
      </div>
    </div>
    <div class="ant-table-wrapper pages-home-trades-table" style="margin-top: 40px">
      <div class="ant-spin-nested-loading">
        <div class="ant-spin-container">
          <div class="ant-table ant-table-middle ant-table-scroll-position-left">
            <div class="ant-table-content">
              <div class="ant-table-body">
              <!-- 进行中 -->
                <table v-if="'ongoing'===currentSalesPromotion">
                  <section  class="pages-ucenter-profile-index-item" language="zh">
                    <section  class="pages-ucenter-profile-index-content pages-ucenter-profile-features-features">
                      <div  class="pages-ucenter-profile-features-feature background-color" v-for="item in sales_promotion.inprogress">

                        <a @click="ShowSalesPromotion(item.sale_token_id)">
                          <div  class="pages-ucenter-profile-features-info" style="background-color: #FFFFFF; border-radius: 8px">
                            <div  style="margin: 7px 7px 0px 7px;">
                              <div class="header">
                                <div style="width: 80%">
                                  <div  class="features_msg bb-logo">
                                    <img class="image_logo" :src="item.currency_info.icon_url">
                                  </div>
                                  <div class="title_full_name">
                                    <span>
                                      {{item.currency_info.currency_full_name}}
                                    </span></br>
                                    <span style="color: #6a6a6a">
                                      {{$t('abbreviation')}}:
                                      {{item.currency_info.currency_code}}
                                    </span>
                                  </div>
                                </div>
                                <div class="header-state" style="background-color: #006ae3;">
                                  ·{{$t(item.state)}}
                                </div>
                              </div>
                              <div class="bb-image">
                                <img style="width:100%; height:100%" :src="item.icon_banner">
                              </div>

                              <div>
                                <div style="margin-top: 6%;">
                                  <span style="color: #939393; font-size: 13px">
                                    {{$t('total_limit')}}
                                  </span>
                                  <span style="width: 18px; height: 18px; margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2234/mony_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">
                                    {{item.sale_token_amount}} {{item.currency_info.currency_code}}</br>
                                    <!-- <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2256/transformation.png"> -->
                                    <span style="float: right;">({{item.funding_currency_target_amount}} {{item.funding_currency_code}})</span>
                                  </span>
                                </div>
                              </div>

                              <div>
                                <div style="margin-top: 10%;">
                                  <span style="color: #939393; font-size: 13px">{{$t('ended_at')}}</span>
                                  <span style="width: 18px; height: 18px;margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2233/time_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">{{item.ended_at}}</span>
                                </div>
                              </div>

                              <div class="progress_bar_one">
                                <div class="progress_bar_two" :style="{width: item.progress_percentage}"></div>
                              </div>
                              <div style="color: #939393; margin-top: 2%;">{{item.progress_percentage}}</div>

                              <div class="inprogress_state">{{$t(item.state)}}</div>
                            </div>
                          </div>

                        </a>
                      </div>

                    </section>
                  </section>

                </table>

                <!-- 即将到来 -->
                <table v-if="'upcoming'===currentSalesPromotion">
                  <section  class="pages-ucenter-profile-index-item" language="zh">
                    <section  class="pages-ucenter-profile-index-content pages-ucenter-profile-features-features">
                      <div  class="pages-ucenter-profile-features-feature background-color" v-for="item in list">

                        <a @click="ShowSalesPromotion(item.sale_token_id)">
                          <div  class="pages-ucenter-profile-features-info" style="background-color: #FFFFFF; border-radius: 8px">
                            <div  style="margin: 7px 7px 0px 7px;">
                              <div class="header">
                                <div style="width: 80%">
                                  <div  class="features_msg bb-logo">
                                    <img class="image_logo" :src="item.currency_info.icon_url">
                                  </div>
                                  <div class="title_full_name">
                                    <span>
                                      {{item.currency_info.currency_full_name}}
                                    </span></br>
                                    <span style="color: #6a6a6a">
                                      {{$t('abbreviation')}}:
                                      {{item.currency_info.currency_code}}
                                    </span>
                                  </div>
                                </div>
                                <div class="header-state" style="float: right;background-color: #f9f9fc; border: solid 1px #006ae3;color:#006ae3;">
                                  ·{{$t(item.state)}}
                                </div>
                              </div>
                              <div class="bb-image">
                                <img style="width:100%; height:100%" :src="item.icon_banner">
                              </div>

                              <div>
                                <div style="margin-top: 6%;">
                                  <span style="color: #939393; font-size: 13px;">
                                    {{$t('total_limit')}}
                                  </span>
                                  <span style="width: 18px; height: 18px; margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2234/mony_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">
                                    {{item.sale_token_amount}} {{item.currency_info.currency_code}}</br>
                                    <!-- <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2256/transformation.png"> -->
                                    <span style="float: right;">({{item.funding_currency_target_amount}} {{item.funding_currency_code}})</span>
                                  </span>
                                </div>
                              </div>

                              <div>
                                <div style="margin-top: 10%;">
                                  <span style="color: #939393; font-size: 13px">{{$t('ended_at')}}</span>
                                  <span style="width: 18px; height: 18px;margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2233/time_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">{{item.ended_at}}</span>
                                </div>
                              </div>

                              <div class="progress_bar_one">
                                <div class="progress_bar_two" :style="{width: item.progress_percentage}"></div>
                              </div>
                              <div style="color: #939393; margin-top: 2%;">{{item.progress_percentage}}</div>

                              <div class="upcoming_state">{{$t(item.state)}}：{{ item.djs }}</div>
                            </div>
                          </div>

                        </a>
                      </div>
                    </section>
                  </section>
                </table>

                <!-- 已经结束 -->
                <table v-if="'hasended'===currentSalesPromotion">
                  <section  class="pages-ucenter-profile-index-item" language="zh">
                    <section  class="pages-ucenter-profile-index-content pages-ucenter-profile-features-features">
                      <div  class="pages-ucenter-profile-features-feature background-color" v-for="item in sales_promotion.finished">

                        <a @click="ShowSalesPromotion(item.sale_token_id)">
                          <div  class="pages-ucenter-profile-features-info" style="background-color: #FFFFFF; border-radius: 8px">
                            <div  style="margin: 7px 7px 0px 7px;">
                              <div class="header">
                                <div style="width: 80%">
                                  <div  class="features_msg bb-logo">
                                    <img class="image_logo" :src="item.currency_info.icon_url">
                                  </div>
                                  <div class="title_full_name">
                                    <span>
                                      {{item.currency_info.currency_full_name}}
                                    </span></br>
                                    <span style="color: #6a6a6a">
                                      {{$t('abbreviation')}}:
                                      {{item.currency_info.currency_code}}
                                    </span>
                                  </div>
                                </div>
                                <div class="header-state" style="background-color: #dadae4;color: #a5a5ba;">
                                  ·{{$t(item.state)}}
                                </div>
                              </div>
                              <div class="bb-image">
                                <img style="width:100%; height:100%" :src="item.icon_banner">
                              </div>

                              <div>
                                <div style="margin-top: 6%;">
                                  <span style="color: #939393; font-size: 13px">
                                    {{$t('total_limit')}}
                                  </span>
                                  <span style="width: 18px; height: 18px; margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2234/mony_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">
                                    {{item.sale_token_amount}} {{item.currency_info.currency_code}}</br>
                                    <!-- <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2256/transformation.png"> -->
                                    <span style="float: right;">({{item.funding_currency_target_amount}} {{item.funding_currency_code}})</span>
                                  </span>
                                </div>
                              </div>

                              <div>
                                <div style="margin-top: 10%;">
                                  <span style="color: #939393; font-size: 13px">{{$t('ended_at')}}</span>
                                  <span style="width: 18px; height: 18px;margin-left: 3px">
                                    <img style="width: 18px; height: 18px;" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2233/time_image.png">
                                  </span>
                                  <span style="float: right;color: #939393; font-size: 13px">{{item.ended_at}}</span>
                                </div>
                              </div>

                              <div class="progress_bar_one">
                                <div class="progress_bar_two" :style="{width: item.progress_percentage}"></div>
                              </div>
                              <div style="color: #939393; margin-top: 2%;">{{item.progress_percentage}}</div>

                              <div class="finished_state">{{$t(item.state)}}</div>
                            </div>
                          </div>

                        </a>
                      </div>
                    </section>
                  </section>
                </table>

                <!-- 我的打新 -->
                <table v-if="'myfightnew'===currentSalesPromotion">
                  <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
                    style="margin-left: 0%;">
                    <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active">
                      <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                        <div class="ant-col ant-col-3" style="padding-left: 45px;width: 25%;padding-top: 2px; font-size: 14px;">{{$t('time')}}</div>
                        <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px; font-size: 14px;">{{$t('type')}}</div>
                        <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px; font-size: 14px;">{{$t('spend')}}</div>
                        <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px; font-size: 14px;">{{$t('obtain')}}</div>
                      </div>
                      <div class="ant-row-flex my-sales-token" style="padding: 0px 15px;" v-for="(item, index) in my_sales_promotion">

                        <div class="ant-row-flex pages-assets-balances-coinlist-trow" style="padding: 0px 15px;" @click="showDefault(index.toString())">
                          <div style="width: 25%">{{item.created_at}}{{index}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{item.currency_code}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{item.spend}} {{item.currency_code}}</div>
                          <div class="ant-col ant-col-3" style="text-align: center;width: 25%;">{{item.obtain}} {{item.funding_currency_code}}</div>
                        </div>
                        <div style="width: 100%; display: none; background-color: #F8F8FF" :id="index">
                          <div style="padding-top: 0.5%;">
                            <span style="margin-left: 4%" class="font-family">活动锁定数量: 200 USDT</span>
                            <span style="margin-left: 25%" class="font-family">活动锁定数量: 200 USDT</span>
                            <span style="margin-left: 24.5%" class="font-family">活动锁定数量: 200 USDT</span>
                          </div>
                          <div style="width: 87%; height: 2px; margin: 0.5% 2% 0% 3%; background-color: #dadae4"></div>
                          <div style="padding: 0% 0% 2% 0%" class="ant-row-flex pages-assets-balances-coinlist-trow-show" v-for="(sale_token_asset_distribution, i) in item.sale_token_asset_distributions">
                            <div class="ant-col ant-col-3" style="padding-top: 0.5%; text-align: left; width: 36.5%;margin-left: 4%">
                              第{{i+1}}轮
                            </div>
                            <div class="ant-col ant-col-3" style="text-align: left; width: 33.5%;">
                              {{sale_token_asset_distribution.created_at}}
                            </div>
                            <div class="ant-col ant-col-3" style="text-align: right; width: 14.5%;">
                              + {{sale_token_asset_distribution.distribution_amount}} {{sale_token_asset_distribution.funding_currency_code}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div role="tabpanel" aria-hidden="true" class="ant-tabs-tabpane ant-tabs-tabpane-inactive"></div>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
.font-family{
  font-family: "黑体";
}
.my-sales-token{
  border-top: solid 1px var(--assets-new-list-boder-color);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--main-color);
  display:block;
  overflow:hidden;
  /*height: 40px;*/
}
.pages-assets-balances-coinlist-trow-show{
  /*border-top: solid 1px var(--assets-new-list-boder-color);*/
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--main-color);
  height: 20px;
}
.pages-ucenter-profile-index-item{
  background-color: var(--sales-promotion-bacolor);
}
.pages-ucenter-profile-features-feature{
  width: 275px;
  height: 345px;
  background-color: #f9f9fc;
  border-radius: 13px;
  margin: 28px 10px 10px 10px;
}
.hand{
  cursor:pointer;
}
.header{
  display: flex;
  justify-content: space-between;
  background-color: #F2F2F2;
  width: 100%;
  height: 60px;
  border-radius: 8px
}
.header-state{
  float: right;
  width: 70px;
  height: 21px;
  margin: 12% 3% 0% 0%;
  padding-top: 1px;
  text-align: center;
  color: #f8f8ff;
  border-radius: 100px;
}
.image_logo{
  width:50px;
  height: 50px;
  border-top-right-radius: 52px;
  border-bottom-right-radius:52px;
  border-bottom-left-radius:50px;
  margin-top: 4px;
}
.bb-logo{
  width: 62px;
  height: 70px;
  background-color: #ffffff;
  float: left;
  border-top-right-radius: 50px;
  border-bottom-right-radius:50px;
  /*border-bottom-left-radius:50px;*/
  padding: 5px;
  margin-top: -4px;
}
.title_full_name{
  float: left;
  width: 65%;
  margin-top: 15px;
  /*text-align: center;*/
  padding-left: 8px;
}
.bb-image{
  margin-top: 5%;
  height: 35%;
  border-radius: 30px;
}
.progress_bar_one{
  margin-top: 15px;
  height: 5px;
  background-color: #dadae4;
}
.progress_bar_two{
  height: 5px;
  background-color: #006cdb;
  max-width: 100%
}
.inprogress_state{
  margin-top: 2%;
  width: 100%;
  height: 36px;
  background: -webkit-linear-gradient(left, #7655e9,#4f8de9);
  color:#f6f6ff;
  border-radius: 8px;
  text-align: center;
  padding-top:7px;
  font-size: 15px;
}
.upcoming_state{
  margin-top: 2%;
  width: 100%;
  height: 36px;
  background-color: #f9f9fc;
  border-radius: 8px;
  border: solid 1px #006ae3;
  color:#006ae3;
  border-radius: 8px;
  text-align: center;
  padding-top:7px;
  font-size: 15px;
}
.finished_state{
  margin-top: 2%;
  width: 100%;
  height: 36px;
  background-color: #dadae4;
  color:#a5a5ba;
  border-radius: 8px;
  text-align: center;
  padding-top:7px;
  font-size: 15px;
}
.tab-spacing{
  margin:30px;
}
.font-color{
  color: #006cdb;
}
.background-color{
  background-color: #f9f9fc;
}
.left-spacing{
  margin-left: 19%;
}
.bb-title{
  font-size: 14px;
  color: #006cdb;
}
</style>
<script type="text/javascript">
function InitTime(endtime){
    var dd,hh,mm,ss = null;
    var time = parseInt(endtime) - new Date().getTime();
    if(time<=0){
        return '00'+"天"+'00'+"小时"+'00'+"分"+'00'+"秒"
    }else{
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time  % 60);
        var str = dd+"天"+hh+"小时"+mm+"分"+ss+"秒";
        return str;
    }
}
import Vue from 'vue'
export default {
  data(){
    return {
      sales_promotion: {},
      my_sales_promotion: {},
      list: [],
      selectItem:[]
    }
  },
  props: {
  },
  watch: {
    sales_promotion(){
      this.getList()
    },
    selectItem(newVal, oldVal){
      console.log(newVal)
      console.log(oldVal)
      if(oldVal[0] !== undefined){
        document.getElementById(oldVal.toString()).style.display="none";
      }
      if(newVal[0] !== undefined){
        document.getElementById(newVal.toString()).style.display="";
      }
    }
  },
  created(){

  },
  computed: {
  },
  mounted() {

    setInterval( ()=> {
        for (var key in this.list) {
            var aaa = parseInt( this.list[key]["started_at_int"]+"000" );
            var bbb = new Date().getTime();
            var rightTime = aaa - bbb;
            if (rightTime > 0) {
              var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
              var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
              var mm = Math.floor((rightTime / 1000 / 60) % 60);
              var ss = Math.floor((rightTime / 1000) % 60);
            }else{
              var dd = '00';
              var hh = '00';
              var mm = '00';
              var ss = '00';
            }
            this.list[key]["djs"] = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        }
    }, 1000);
    this.getSalesPromotion()
  },
  methods: {
    showDefault(id){
      if(this.selectItem[0] === id.toString()){
        this.selectItem=[]
      }else{
        this.selectItem=[]
        this.selectItem.push(id.toString())
      }
    },
    getList(){
      var ssss = this.sales_promotion.coming
      ssss.map( (obj,index)=>{
          this.$set(
              obj,"djs",InitTime(obj.started_at_int )
          );
      })
      this.list = ssss;
    },
    SelectTab(tab_type){
      Vue.setGlobalVariable({'sales_promotion': tab_type});
      if(tab_type === 'myfightnew'){
        this.getMySalesPromotion()
      }
    },
    ShowSalesPromotion(id){
      //显示当前打新币种信息
      this.$router.push({ path: ('/show_sales_promotion/' + id)});
    },
    getSalesPromotion(){
      this.getUrl('/www/sales_promotion/get_sales_promotion').then((response)=>{
        if (response.data.code ===  20000) {
          this.sales_promotion = response.data.sales_promotion;
        }
      })
    },

    getMySalesPromotion(){
      this.$nextTick(()=>{
        this.getUrl('/www/sales_promotion/my_sales_promotion').then((response)=>{
          if (response.data.code ===  20000) {
            this.my_sales_promotion = response.data.my_sales_promotion;
          }
        })
      });
    }
  }
}
</script>
