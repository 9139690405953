<template>
    <div class="pages-home-index-main" style="background-color: #1e1b26;">
   		<div class="main_index">
   			<div class="main_index_text">
   				<div>{{$t("sync_on_the_app_side")}}</div>
   				<div style="margin-top: 16px">
          {{$t("quick_transactions_anytime_anywhere")}}</div>
   			</div>
   		</div>
    </div>
</template>
<style type="text/css" scoped>
	.main_index{
		display: flex;
		flex-direction: column;
    align-items: center;
    height: 400px;
    color: #ffffff;
    font-size: 25px;
    margin-right: 194px;
	}
  .main_index_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left; 
    margin-top: 150px;
    width: 650px;
  }
</style>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String
  }
}
</script>
