<template>
<section class="pages-ucenter-commonform-content">
  <div class="redio_type" v-if="is_show_unbind">
    <div v-for="item in radioData" :style="{'margin-left': item.label == 'unbind' ? '40px' : '0px'}">
      {{item.name}}&nbsp;<img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2052/redio_no_checkd.png" v-if="item.label != checkTypeVal" @click="checkRedio(item.label)" style="margin-bottom: 4px;">
      <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2051/redio_checkd.png" v-if="item.label == checkTypeVal" style="margin-bottom: 4px;">
    </div>
  </div>
  <div v-if="email_is_bind" class="now_email_msg">{{$t('the_mailbox_you_are_binding_is')}}：{{now_email}}</div>
  <form class="ant-form ant-form-vertical ant-form-hide-required-mark" v-if="checkTypeVal == 'bind'">
    <!-- <div class="pages-ucenter-bind-phone-index-captcha" v-if="email_is_bind">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorEmailMsmClassOld ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('original_email_verification_code')">{{$t("original_email_verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorEmailMsmClassOld ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="emailCode" class="ant-input ant-input-lg" v-model="emailMsmOld" @keyup="inputEmailMsmOld()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="emailMsmIsAbleOld" @click="emailMsmSendOld" style="background-color: #006cdb;opacity: 1;border: 0px">
                      <span style="font-size: 14px;">{{emailMsmButtonOld}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorEmailMsmDivOld">{{msmErrorEmailMsgOld}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorEmailClass ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_mailbox_you_want_to_bind')"  v-if="!email_is_bind">{{$t("please_enter_the_mailbox_you_want_to_bind")}}</label>
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_email_you_want_to_change')"  v-if="email_is_bind">{{$t("please_enter_the_email_you_want_to_change")}}</label>
        </div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  :class="[isShowErrorEmailClass ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="smsCode" class="ant-input ant-input-lg" v-model="email" @keyup="inputEmail()" maxlength="50">
                  <span class="">
                  </span>
                </span>
              </span>
            </span>
            <div class="ant-form-explain" v-if="isShowErrorEmailDiv">{{emailErrorMsg}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorEmailMsmClass ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('verification_code')">{{$t("verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorEmailMsmClass ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="emailCode" class="ant-input ant-input-lg" v-model="emailMsm" @keyup="inputEmailMsm()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="emailMsmIsAble" @click="emailMsmSend" style="background-color: #006cdb;opacity: 1;border: 0px">
                      <span style="font-size: 14px;">{{emailMsmButton}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorEmailMsmDiv">{{msmErrorEmailMsg}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pages-ucenter-commonform-handle">
      <!-- <button type="button" class="ant-btn ant-btn-primary" style="font-weight:normal;width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="submitIsAble" :title="$t('confirm_binding')" @click="submitBindEmail"> -->
      <button type="button" class="ant-btn ant-btn-primary" style="font-weight:normal;width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="submitIsAble" :title="$t('confirm_binding')" @click="emailBindValidation">
        <span>{{$t("confirm_binding")}}</span>
      </button>
      <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
    </div>
  </form>

  <form class="ant-form ant-form-vertical ant-form-hide-required-mark" v-if="checkTypeVal == 'unbind' && is_show_unbind">
    <!-- <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorEmailMsmClassUnbind ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('verification_code')">{{$t("verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorEmailMsmClassUnbind ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="emailCode2" class="ant-input ant-input-lg" v-model="emailMsmUnbind" @keyup="inputEmailMsmUnbind()" maxlength="50">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="emailMsmIsAbleUnbind" @click="emailMsmSendUnbind" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px">
                      <span style="font-size: 14px;">{{emailMsmButtonUnbind}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorEmailMsmDivUnbind">{{msmErrorEmailMsgUnbind}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="pages-ucenter-commonform-handle">
      <!-- <button type="button" class="ant-btn ant-btn-primary" style="font-weight:normal;width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="submitIsAbleUnbind" :title="$t('confirm_binding')" @click="submitBindEmailUnbind"> -->
      <button type="button" class="ant-btn ant-btn-primary" style="font-weight:normal;width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :title="$t('confirm_binding')" @click="OperationValidation('unbindEmail')">
        <span>{{$t("unbind")}}</span>
      </button>
      <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
    </div>
  </form>
</section>
</template>
<style type="text/css">
.ant-btn-primary{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.redio_type{
  display: flex;
  justify-content:flex-start;
  font-size: 20px;
  margin-bottom: 30px;
  color: var(--main-color);
}
.redio_type div input{
  width: 20px;
  height: 18px;
}
.now_email_msg{
  color: #e45858;
  margin-bottom: 15px;
  font-size: 13px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
import i18n from '../../i18n/i18n'
import store from '../../vuex/store'
export default {
  props: {
      isHideCountry: Boolean,
      defaultCountryIconClass:String,
      defaultCountryMember:String,
      isHideLanguage: Boolean
  },
  mounted() {

  },
  data() {
     return {
      email: '',
      isShowErrorEmailClass: false,
      isShowErrorEmailDiv:false,
      emailErrorMsg:'',
      emailMsm:'',
      emailMsmOld:'',
      emailMsmUnbind:'',
      msmErrorEmailMsg:'',
      msmErrorEmailMsgOld:'',
      isShowErrorEmailMsmClass:false,
      isShowErrorEmailMsmDiv:false,
      isShowErrorEmailMsmClassOld:false,
      isShowErrorEmailMsmDivOld:false,
      isShowErrorEmailMsmClassUnbind:false,
      isShowErrorEmailMsmDivUnbind:false,
      emailMsmCd:60,
      emailMsmCdOld:60,
      emailMsmCdUnbind:60,
      emailMsmIsAble:true,
      emailMsmIsAbleOld:false,
      emailMsmIsAbleUnbind:false,
      checkTypeVal: 'bind',
      bindMsmIsSend: false,
      bindMsmIsSendOld: false,
      unBindMsmIsSend: false
     }
  },
  computed: {
    now_email: function () {
      if(this.currentJudgeIsLoggedIn){
          return this.currentUserInfo.email;
      }else{
          return '';
      }
    },
    email_is_bind: function (){
      if(this.currentJudgeIsLoggedIn){
          if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
              return false;
          }else{
              return true;
          }
      }else{
          return false;
      }
    },
    radioData: function (){
      return [
        {
          label:'bind',
          name: this.$t('replace_the_bound_mailbox')
        },
        {
          label:'unbind',
          name: this.$t('unbind')
        }
      ]
    },
    is_show_unbind: function (){
        if(this.currentJudgeIsLoggedIn){
            if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
                return false;
            }else{
                if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
                  return false;
                }else{
                  return true;
                }
            }
        }else{
            return false;
        }
    },
    submitIsAble: function () {
      if(this.email_is_bind){
        if(!this.isShowErrorEmailClass && !this.isShowErrorEmailDiv && !this.isShowErrorEmailMsmClass && !this.isShowErrorEmailMsmDiv && this.emailMsm != '' ){
            return false;
        }else{
            return true;
        }
      }else{
        if(!this.isShowErrorEmailClass && !this.isShowErrorEmailDiv && !this.isShowErrorEmailMsmClass && !this.isShowErrorEmailMsmDiv && this.emailMsm != ''){
              return false;
          }else{
              return true;
          }
      }
    },
    submitIsAbleUnbind: function () {
      if(!this.isShowErrorEmailMsmClassUnbind && !this.isShowErrorEmailMsmDivUnbind && this.emailMsmUnbind != ''){
          return false;
      }else{
          return true;
      }
    },
    emailMsmButton: function () {
       if(this.emailMsmCd > 0 && this.emailMsmIsAble == true && this.isShowErrorEmailClass == false && this.isShowErrorEmailDiv == false && this.email != '' && this.bindMsmIsSend){
           return "("+(this.emailMsmCd )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    emailMsmButtonOld: function () {
       if(this.emailMsmCdOld > 0 && this.emailMsmIsAbleOld == true && this.bindMsmIsSendOld){
           return "("+(this.emailMsmCdOld )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    emailMsmButtonUnbind: function () {
       if(this.emailMsmCdUnbind > 0 && this.emailMsmIsAbleUnbind == true && this.unBindMsmIsSend){
           return "("+(this.emailMsmCdUnbind )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    }
  },
  methods: {
    checkRedio(radio_label){
      this.checkTypeVal = radio_label;
    },

    emailBindValidation(){
      this.postUrl('/www/user_center/valid_new_email', {
          lang:this.currentLang,
          email: this.email,
          email_code: this.emailMsm,
       }).then((response) => {
          if(response.data.code == 20000){
            Vue.setGlobalVariable({
              'new_email': this.email
            });
            // 新手机号验证通过后开始热操作验证
            this.OperationValidation("bindEmail");
          }
       });
    },

    emailMsmSendUnbind: function () {
      this.emailMsmIsAbleUnbind = true;
       //发送邮箱验证码
       this.postUrl('/www/user_center/send_unbind_email_code', {
          lang:this.currentLang,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.unBindMsmIsSend = true;
            this.timer2 = setInterval( () => {
              if(this.emailMsmCdUnbind > 1){
                 this.emailMsmIsAbleUnbind = true;
                 this.emailMsmCdUnbind = this.emailMsmCdUnbind - 1;
             }else{
                 //重置
                 this.emailMsmIsAbleUnbind = false;
                 this.emailMsmCdUnbind = 60;
                 this.unBindMsmIsSend = false;
                 clearInterval(this.timer2)
             }
    　　　　}, 1000);
          }else{
            this.emailMsmIsAbleUnbind = false;
          }
       });
　　 },
    emailMsmSend: function () {
      this.emailMsmIsAble = true;
      //发送邮箱验证码
      this.postUrl('/www/user_center/send_bind_email_code', {
          lang:this.currentLang,
          email: this.email
      }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.bindMsmIsSend = true;
            this.timer1 = setInterval( () => {
              if(this.emailMsmCd > 1){
                 this.emailMsmIsAble = true;
                 this.emailMsmCd = this.emailMsmCd - 1;
             }else{
                 //重置
                 this.emailMsmIsAble = false;
                 this.emailMsmCd = 60;
                 this.bindMsmIsSend = false;
                 clearInterval(this.timer1)
             }
    　　　　}, 1000);
          }else{
            this.emailMsmIsAble = false;
          }
       });
　　 },
    emailMsmSendOld: function () {
      this.emailMsmIsAbleOld = true;
      //发送邮箱验证码
      this.postUrl('/www/user_center/send_bind_old_email_code', {
          lang:this.currentLang
      }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.bindMsmIsSendOld = true;
            this.timer1Old = setInterval( () => {
              if(this.emailMsmCdOld > 1){
                 this.emailMsmIsAbleOld = true;
                 this.emailMsmCdOld = this.emailMsmCdOld - 1;
             }else{
                 //重置
                 this.emailMsmIsAbleOld = false;
                 this.emailMsmCdOld = 60;
                 this.bindMsmIsSendOld = false;
                 clearInterval(this.timer1Old)
             }
    　　　　}, 1000);
          }else{
            this.emailMsmIsAbleOld = false;
          }
       });
　　 },
    inputEmailMsm(){
      this.emailMsm = this.emailMsm.replace(/[^0-9]/g,'');
      if (this.emailMsm == '') {
          //不合法 不可为空
          this.msmErrorEmailMsg = this.$t('please_enter_the_email_verification_code');
          this.isShowErrorEmailMsmClass = true;
          this.isShowErrorEmailMsmDiv = true;
      }else if(this.emailMsm.length != 6){
          this.msmErrorEmailMsg = this.$t('the_format_of_the_mailbox_verification_code_is_incorrect');
          this.isShowErrorEmailMsmClass = true;
          this.isShowErrorEmailMsmDiv = true;
      }else{
          //正确
          this.isShowErrorEmailMsmClass = false;
          this.isShowErrorEmailMsmDiv = false;
      }
    },
    inputEmailMsmOld(){
      this.emailMsmOld = this.emailMsmOld.replace(/[^0-9]/g,'');
      if (this.emailMsmOld == '') {
          //不合法 不可为空
          this.msmErrorEmailMsgOld = this.$t('please_enter_the_email_verification_code');
          this.isShowErrorEmailMsmClassOld = true;
          this.isShowErrorEmailMsmDivOld = true;
      }else if(this.emailMsmOld.length != 6){
          this.msmErrorEmailMsgOld = this.$t('the_format_of_the_mailbox_verification_code_is_incorrect');
          this.isShowErrorEmailMsmClassOld = true;
          this.isShowErrorEmailMsmDivOld = true;
      }else{
          //正确
          this.isShowErrorEmailMsmClassOld = false;
          this.isShowErrorEmailMsmDivOld = false;
      }
    },
    inputEmailMsmUnbind(){
      this.emailMsmUnbind = this.emailMsmUnbind.replace(/[^0-9]/g,'');
      if (this.emailMsmUnbind == '') {
          //不合法 不可为空
          this.msmErrorEmailMsgUnbind = this.$t('please_enter_the_email_verification_code');
          this.isShowErrorEmailMsmClassUnbind = true;
          this.isShowErrorEmailMsmDivUnbind = true;
      }else if(this.emailMsmUnbind.length != 6){
          this.msmErrorEmailMsgUnbind = this.$t('the_format_of_the_mailbox_verification_code_is_incorrect');
          this.isShowErrorEmailMsmClassUnbind = true;
          this.isShowErrorEmailMsmDivUnbind = true;
      }else{
          //正确
          this.isShowErrorEmailMsmClassUnbind = false;
          this.isShowErrorEmailMsmDivUnbind = false;
      }
    },
    inputEmail(){
      this.email = this.email.replace(/[\u4e00-\u9fa5]/ig,'');
      let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.email == "") {
        //不合法 不可为空
        this.emailErrorMsg = this.$t("please_input_your_email");
        this.isShowErrorEmailClass = true;
        this.isShowErrorEmailDiv = true;
        this.emailMsmIsAble = true;
      } else if (this.email.length <= 10 || !reg.test(this.email)) {
        //不合法 格式错误
        this.emailErrorMsg = this.$t("email_address_format_is_incorrect");
        this.isShowErrorEmailClass = true;
        this.isShowErrorEmailDiv = true;
        this.emailMsmIsAble = true;
      } else {
        //正确
        this.isShowErrorEmailClass = false;
        this.isShowErrorEmailDiv = false;
        this.emailMsmIsAble = false;
      }
    },
  }
}
</script>
