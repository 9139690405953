<template>
	<div class="pages-home-index-register">
        <div class="pages-home-index-title">{{$t("registered")}} {{currentHomeText}} {{$t("account_number")}}，{{$t("start_the_trading_journey")}}</div>
        <div style="margin-top: 20px;">
          <input type="text" :placeholder="$t('email_mobile_number')" :class="[isShowError ? 'showError' : '']" @keyup="inputEmailOrMobile" v-model="email_mobile_number" maxlength="50">
          <button @click="submitEmailOrMobile" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" :title="$t('registered')">{{$t("registered")}}</button>
          <!-- <div class="ant-form-explain" style="color: #df4e4e;font-size: 18px;" v-if="isShowError">{{$t('email_or_mobile_phone_number_format_is_incorrect')}}</div> -->
        </div>
    </div>
</template>
<style type="text/css" scoped>
    .showError{
        border: 2px solid #df4e4e;
    }
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String
  },
  data() {
     return {
        email_mobile_number:'',
        isShowError:false,
        satisfyType:''
     }
  },
  methods: {
    submitEmailOrMobile(){
        //提交注册
        if(this.email_mobile_number == ''){
          this.$message({
            message: this.$t('please_fill_in_your_email_mobile_phone_number_first'),
            type: 'error'
          })
          return false
        }
        if(this.satisfyType == '' || this.isShowError){
          this.$message({
            message: this.$t('please_fill_in_your_email_mobile_phone_number_carefully'),
            type: 'error'
          })
          return false
        }
        location.href = Vue.getValueByKey('cas_path')+'/sign_up?service=' + window.location.href.split('?')[0] + '&login_type=' + this.satisfyType + '&number=' + this.email_mobile_number + '&lang=' + this.language
    },
    inputEmailOrMobile(){
      this.email_mobile_number = this.email_mobile_number.replace(/[\u4e00-\u9fa5]/ig,'');
      let mobile_reg = /^1[0-9]{10}$/
      let email_reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if(mobile_reg.test(this.email_mobile_number)){
          this.satisfyType = "mobile";
          this.isShowError = false;
          return false;
      }
      if(email_reg.test(this.email_mobile_number)){
          this.satisfyType = "email";
          this.isShowError = false;
          return false;
      }
      this.satisfyType = '';
      this.isShowError = true;
    }
  },
  computed: {

  }
}
</script>
