module.exports = {
  website_name: 'GDA',
  white_logo: 'https://showmethemoney.fuxinsci.com/image_after_2019_06/navc_logo_light@2x.png',
  black_logo: 'https://showmethemoney.fuxinsci.com/image_after_2019_06/navc_logo_dark@2x.png',
  android_download_url: 'aaa',
  android_download_qrcode: 'bbb',
  ios_download_url: 'ccc',
  ios_download_qrcode: 'ddd',
  my_assets_banner: 'ee',
}
