<template>
    <div class="pages-home-trades-main" style="margin-bottom: 50px">
        <h1 style="font-size: 22px;color: var(--main-color-new)">{{$t('innovation_zone')}}：</h1>
        <div class="pages-home-trades-tabs">
            <div class="ant-row">
                <div class="ant-col ant-col-20" style="width: var(--page-content-width);display: flex;align-items: center;" onselectstart="return false;">
                    <a @click="$emit('change-qttt-innovation','zxq')" class="unit_type_list " :class="['zxq' === quotationTypeValueInnovation ? 'pages-home-trades-active' : null ]" :title="$t('self_selected_area')">{{$t('self_selected_area')}}</a>
                    <a v-for="item in quotationType" @click="$emit('change-qttt-innovation',item)" class="unit_type_list " :class="[item === quotationTypeValueInnovation ? 'pages-home-trades-active' : null ]" :title="item.toUpperCase() + $t('market')">{{item.toUpperCase()}}{{$t('market')}}</a>
                </div>
            </div>
        </div>
        <div class="ant-table-wrapper pages-home-trades-table">
            <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                    <div class="ant-table ant-table-middle ant-table-scroll-position-left">
                        <div class="ant-table-content">
                            <div class="ant-table-body">
                                <table class="">
                                    <colgroup>
                                        <col style="width: 25px; min-width: 25px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 180px; min-width: 180px;">
                                        <col>
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                    </colgroup>
                                    <div class="ant-table-thead" style="width: 100%;">
                                        <div style="width: 100%;justify-content: space-between;display: flex;background-color: var(--assets-rwd-home-list);"   onselectstart="return false;">
                                            <th class="" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div style="text-align: left;margin-left: 25px;line-height: 24px;">
                                                        <span class="ant-table-column-title">{{$t("transaction_pair")}}</span>
                                                        <span class="ant-table-column-sorter"></span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" style="" @click="$emit('order-list-innovation','last_price')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("latest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnInnovation == 'last_price' && originalInnovation == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnInnovation != 'last_price' || originalInnovation != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnInnovation == 'last_price' && originalInnovation == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnInnovation != 'last_price' || originalInnovation != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th"   @click="$emit('order-list-innovation','price_change_ratio')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("quote_change")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnInnovation == 'price_change_ratio' && originalInnovation == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnInnovation != 'price_change_ratio' || originalInnovation != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnInnovation == 'price_change_ratio' && originalInnovation == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnInnovation != 'price_change_ratio' || originalInnovation != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-innovation','high')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("highest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnInnovation == 'high' && originalInnovation == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnInnovation != 'high' || originalInnovation != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnInnovation == 'high' && originalInnovation == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnInnovation != 'high' || originalInnovation != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-innovation','low')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("lowest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnInnovation == 'low' && originalInnovation == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnInnovation != 'low' || originalInnovation != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnInnovation == 'low' && originalInnovation == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnInnovation != 'low' || originalInnovation != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-innovation','volume')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("24h_volume")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnInnovation == 'volume' && originalInnovation == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnInnovation != 'volume' || originalInnovation != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnInnovation == 'volume' && originalInnovation == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnInnovation != 'volume' || originalInnovation != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                        </div>
                                    </div>
                                    <div class="ant-table-tbody">
                                        <div class="ant-table-row ant-table-row-level-0 data_content" v-for="(item, i) in quoteCenterInnovationData" style="justify-content: space-between;display: flex;"  :class = "[i % 2 == 0 ? '' : 'odd']" @click="goTrading(item.id)">
                                            <td class="" style="display: flex;width: 20%;">
                                                <span class="pages-home-trades-favorite" @click="$emit('check-market-favorite',item.id)" @click.stop="hideAlert($event)">
                                                    <i class="iconfont " :class="[currentMainInnovationFavoriteIds.indexOf(item.id) > '-1'  ? 'icon-xingxing' : 'icon-xingxu']"></i>
                                                </span>
                                                <div class="pages-home-trades-tradeBoom" style="padding-left: 10px;">{{item.ask_unit_upcase}}/{{item.bid_unit_upcase}}</div>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                <div>
                                                    <span>{{item.last_price}}</span>
                                                </div>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                <span :style="{'color': item.price_change_ratio.indexOf('-') == -1 ? 'var(--rise-color)' : 'var(--down-color)'}">{{item.price_change_ratio}}</span>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.high}}
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.low}}
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.volume}}
                                            </td>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css" scoped>
.unit_type_list{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.5s;
}
.data_content{
    animation: fadeEffect .5s;
}
.ant-table-tbody{
    width: 100%;
    height: 100%;
    overflow: auto;
}
@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}
.ant-table-row:hover{
    background-color: var(--box-hover-bg);
}
.ant-table-column-has-sorters-th:hover{
    background-color: var(--home-main-line-bgcolor);
}
td{
    padding: 12px 8px;
    text-align: center;
}
th{
    padding: 12px 8px;
    cursor: pointer;
    text-align: center;
    background-color: var(--home-trade-header-bgcolor);
    color: var(--main-color-middle);
}
.odd{
    background-color: var(--trades-list-line-odd)
}
.icon-xingxing{
    color:rgb(250, 173, 20);
    font-size: 12px;
}
.ant-table-column-has-sorters{
    text-align: center;
}
.icon-xingxu{
    color:var(--trade-color-light);
    font-size: 12px;
}
.ant-table-column-title {
    color: #9b9b9b;
}
.storters_item{
    display: flex;
    justify-content: center;
    line-height: 24px;
}
.inner_item{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String,
    quotationTypeValueInnovation: String,
    quoteCenterInnovationData: Array,
    quoteCenterInnovationPassCard: Array,
    checkOrderColumnInnovation: String,
    orderTypeInnovation: String,
    originalInnovation: String,
    quotationType: Array
  },
  watch: {
    currentWwwHomeTradesMainInnovationList(newVal) {
        if (newVal.length === 0){
            return
        }
        //websocket数据更新 页面及时渲染
        this.$emit('reload-list-data')
    }
  },
  created(){
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    goTrading(market_id){
        //到达trading币种页
        window.open(Vue.getValueByKey('trading_path')+ '/exchange/' + market_id + '?lang=' + this.currentLang, "_blank");
    },
    hideAlert(){

    }
  }
}
</script>
