<template>
	<div class="pages-assets-balances-navigation-container isShow">
	    <div class="pages-assets-balances-asset-account" style="margin-top: 0px;">
	        <div class="ant-tabs ant-tabs-top ant-tabs-line">
	            <div class="showPre1">
		            <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
		                style="margin-left: 0%;">
		                <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active">
		                    <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
		                        <div class="ant-col ant-col-3" style="padding-left: 15px;width: 13%;padding-top: 2px;">{{$t("digital_currency")}}</div>
		                        <div class="ant-col ant-col-3" style="text-align: center;width: 20%;padding-top: 2px;">{{$t("quantity_available")}}</div>
		                        <div class="ant-col ant-col-3" style="text-align: center;width: 20%;padding-top: 2px;">{{$t("locked_quantity")}}</div>
		                        <div class="ant-col ant-col-3" style="text-align: center;width: 20%;padding-top: 2px;">{{$t("lock_the_new_quantity")}}</div>
		                        <div class="ant-col ant-col-8" style="text-align: right; padding-right: 15px;width: 27%;padding-top: 2px;">{{$t("operating")}}&nbsp;<img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2031/hand.png" width="21px" style="padding-bottom: 2px;" /></div>
		                    </div>
		                    <div class="ant-row-flex pages-assets-balances-coinlist-trow"  v-for="item in accountList" style="padding: 0px 15px;">
			                    <div class="ant-col ant-col-3" style="display: flex;justify-content:flex-start;line-height: 30px;width: 13%;">
			                    	<div :style="'background-image:url(' + item.icon_url + ');'" class="coin_img"></div>
			                    	<div style="margin-left: 5px;">{{item.upcase}}</div>
			                    </div>
			                    <div class="ant-col ant-col-3" style="text-align: center;width: 20%;">{{item.balance}}</div>
			                    <div class="ant-col ant-col-3" style="text-align: center;width: 20%;">{{item.locked}}</div>
			                    <div class="ant-col ant-col-3" style="text-align: center;width: 20%;">{{item.locked_token_sale_amount}}</div>
			                    <div class="ant-col ant-col-8 pages-assets-balances-coinlist-action" style="width: 27%;" v-if="currentLang == 'zh' || currentLang == 'kr'">
			                        <button type="button" class="ant-btn ant-btn-default" :title="$t('rech_arge')" :disabled = "item.is_depositable ? false : 'disabled'" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 23%;" :style="{'color': item.is_depositable ? '#006cdb' : 'rgba(101,105,141,0.63)'}" @click="goRwd('/rwd_assets/'+ item.code,'recharge')"><span>{{$t("rech_arge")}}</span></button>
			                        <button type="button" class="ant-btn ant-btn-default" :title="$t('with_draw')"   :disabled = "item.is_withdrawable ? false : 'disabled'" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 23%;" :style="{'color': item.is_withdrawable ? '#04c857' : 'rgba(101,105,141,0.63)'}" @click="goRwd('/rwd_assets/'+ item.code,'withdrawal')"><span>{{$t("with_draw")}}</span></button>
			                        <button type="button" class="ant-btn ant-dropdown-trigger"  :title="$t('transfer')"  :disabled = "item.is_transfered ? false : 'disabled'"  style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 23%;" :style="{'color': item.is_transfered ? '#f3aa2d' : 'rgba(101,105,141,0.63)'}"  @click="goRwd('/rwd_assets/'+ item.code,'transfer')"><span>{{$t("transfer")}}</span></button>
			                    </div>
			                    <div class="ant-col ant-col-8 pages-assets-balances-coinlist-action" style="width: 27%;" v-if="currentLang != 'zh' && currentLang != 'kr'">
			                        <button type="button" class="ant-btn ant-btn-default" :title="$t('rech_arge')" :disabled = "item.is_depositable ? false : 'disabled'"  style="color: #006cdb;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 33%;" :style="{'color': item.is_depositable ? '#006cdb' : 'rgba(101,105,141,0.63)'}" @click="goRwd('/rwd_assets/'+ item.code,'recharge')"><span>{{$t("rech_arge")}}</span></button>
			                        <button type="button" class="ant-btn ant-btn-default" :title="$t('with_draw')" :disabled = "item.is_withdrawable ? false : 'disabled'" style="color: #04c857;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 33%;" :style="{'color': item.is_withdrawable ? '#04c857' : 'rgba(101,105,141,0.63)'}" @click="goRwd('/rwd_assets/'+ item.code,'withdrawal')"><span>{{$t("with_draw")}}</span></button>
			                        <button type="button" class="ant-btn ant-dropdown-trigger"  :title="$t('transfer')" :disabled = "item.is_transfered ? false : 'disabled'" style="color: #f3aa2d;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 33%;" :style="{'color': item.is_transfered ? '#f3aa2d' : 'rgba(101,105,141,0.63)'}"    @click="goRwd('/rwd_assets/'+ item.code,'transfer')"><span>{{$t("transfer")}}</span></button>
			                    </div>
			                </div>
		                </div>
		                <div role="tabpanel" aria-hidden="true" class="ant-tabs-tabpane ant-tabs-tabpane-inactive"></div>
		            </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>
<style type="text/css" scoped>
	.isShow{
		border-radius: 12px;
	    margin: 0 auto;
	    width: var(--page-content-width);
	}
	.coin_img{
		width:28px;
		height:28px;
		background: center center no-repeat no-repeat;
		background-size: cover;
	}
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  props: {
        // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
        language: String,
        accountList: Array,
        isCertified: Boolean
  },
  created(){
  },
  computed: {

  },
  mounted() {

  },
  methods: {
  	goRwd(link_url,type){
  		if(type == "transfer"){
  			msg = "user_is_not_certified_to_transfer"
  		}else if(type == "recharge"){
  			msg = "user_is_not_certified_to_recharge_currency"
  		}else if(type == "withdrawal"){
  			msg = "user_is_not_certified_to_withdrawal"
  		}
  		if(!this.isCertified){
  			this.$message({
	        message: this.$t(msg),
	        type: 'error'
	      })
	      return
  		}

  		Vue.setStorageVariable({'rwd_assets_type':type});
      this.$router.push({path:link_url}).catch(data => {  })
  	}
  }
}
</script>
