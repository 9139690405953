import { render, staticRenderFns } from "./SalesPromotionShow.vue?vue&type=template&id=6aba610d&scoped=true"
import script from "./SalesPromotionShow.vue?vue&type=script&lang=js"
export * from "./SalesPromotionShow.vue?vue&type=script&lang=js"
import style0 from "./SalesPromotionShow.vue?vue&type=style&index=0&id=6aba610d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aba610d",
  null
  
)

export default component.exports