<template style="height: 100%;">
<div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width);height: 100%;" class="activity">
     <div class="activity_home"></div>
     <div class="activity_sign"></div>
     <div class="activity_reward"></div>
     <div class="activity_reward_text">
        <div class="text">
          <div>1. {{$t('each_account_can_only_be_bound_to_one_game_platform_account')}} </div>
          <div>2. {{$t('the_mailbox_or_mobile_phone_number_bound_at_the_time_of_registration_can_only_get_the_reward_points_at_the_time_of_registration')}}。</div>
        </div>
     </div>
     <div class="activity_create">
       <button type="button" class="ant-btn ant-dropdown-trigger" style="width: 446px;height: 110px;background: -webkit-linear-gradient(left,#9100b5,#1f02a0);color: #ffffff;font-size: 40px;border: 0px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" @click="bindImmediately"  :title="$t('bind_immediately')">
        <span>{{$t('bind_immediately')}}</span>
      </button>
     </div>
    <el-dialog
      :visible.sync="bindDialogVisible"
      width="670px"
      top="250px"
      :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <div class="bind_dialog">
          <div class="bind_dialog_visible_left">
            <div class="title">{{$t('existing_account_binding')}}</div>
            <div class="password_or_code">
              <div :class="[ checkdPasswordOrCodeValue == item.value ?  'check_password_or_code' : '']" v-for="item in passwordOrCodeData" @click="checkPasswordOrCode(item.value)">{{item.label}}</div>
            </div>
            <div class="account">
              <div class="code" v-if="checkdPasswordOrCodeValue == 'code'">
                <el-form-item  prop="code_account">
                  <el-input :placeholder="codeAccountPlaceHolder" v-model="ruleForm.code_account"  @keyup.native="inputCodeAccount()"></el-input>
                </el-form-item>
              </div>
              <div class="password" v-if="checkdPasswordOrCodeValue == 'password'">
                <el-form-item  prop="password_account">
                  <el-input :placeholder="passwordAccountPlaceHolder" v-model="ruleForm.password_account"  @keyup.native="inputPasswordAccount()"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="code_password">
              <div class="code" v-if="checkdPasswordOrCodeValue == 'code'">
                <el-form-item  prop="code_code">
                  <el-input :placeholder="codeMsmPlaceHolder" v-model="ruleForm.code_code" style="width: 70%;" @keyup.native="inputCodeCode()"></el-input>
                </el-form-item>
                <button type="button" class="ant-btn ant-dropdown-trigger" style="width: 118px;height: 37px;color: #ffffff;font-size: 40px;border: 0px;background-color: #4fb6eb;font-size: 16px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"  :disabled="mobileMsmIsAble" @click="mobileMsmSend" v-if="emailOrMobileValue == 'mobile'" :title="mobileMsmButton">
                  {{mobileMsmButton}}
                </button>
                <button type="button" class="ant-btn ant-dropdown-trigger" style="width: 118px;height: 37px;color: #ffffff;font-size: 40px;border: 0px;background-color: #4fb6eb;font-size: 16px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"  :disabled="emailMsmIsAble" @click="emailMsmSend" v-if="emailOrMobileValue == 'email'" :title="emailMsmButton">
                  {{emailMsmButton}}
                </button>
              </div>
              <div class="password" v-if="checkdPasswordOrCodeValue == 'password'">
                <el-form-item  prop="password_password">
                  <el-input :placeholder="passwordPasswordPlaceHolder" type="password" v-model="ruleForm.password_password" style="width: 70%;" @keyup.native="inputPasswordPassword()"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="button">
              <button type="button" :placeholder="codeMsmPlaceHolder" :title="$t('binding')" class="ant-btn ant-dropdown-trigger" style="width: 348px;height: 37px;color: #ffffff;font-size: 40px;border: 0px;background-color: #4fb6eb;font-size: 16px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" :disabled="bindIsAble" @click="bindActivity">
                  <span>{{$t('binding')}}</span>
              </button>
            </div>
            <div class="email_or_mobile">
              <span v-if="emailOrMobileValue == 'mobile'" @click="checkEmailOrMobile('email')">{{switch_to_email_binding}}</span>
              <span v-if="emailOrMobileValue == 'email'" @click="checkEmailOrMobile('mobile')">{{switch_to_mobile_binding}}</span>
            </div>
          </div>
          <div class="bind_dialog_visible_middle">
          </div>
          <div class="bind_dialog_visible_right">
            <div class="logo">
              <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2420/activity_sign_logo.png" />
            </div>
            <div class="button" style="margin-top: 78px;">
              <button type="button" class="ant-btn ant-dropdown-trigger" :title="$t('one_click_registration')" style="width: 100px;height: 32px;color: #feffff;font-size: 16px;border: 0px;background-color: #3388e9;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" @click="oneClickRegistration">
                  <span>{{$t('one_click_registration')}}</span>
              </button>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="oneClickDialogVisible"
      width="460px"
      top="250px"
      :before-close="oneClickHandleClose">
      <div class="one_click_dialog" style="text-align: center;">
        <div class="title">
          <el-tooltip :content="$t('the_selected_binding_method_will_create_an_account_with_the_same_account_type_and_password_on_the_game_platform')" placement="top">
           <i class="el-icon-question"></i>
          </el-tooltip>&nbsp;
          {{$t('please_select_the_method_to_bind')}}
        </div>
        <div class="radio">
          <el-radio v-model="one_sign_type" label="email">{{$t('email_binding')}}</el-radio>
          <el-radio v-model="one_sign_type" label="mobile">{{$t('phone_binding')}}</el-radio>
        </div>
        <div class="button">
          <button type="button" class="ant-btn ant-dropdown-trigger" :title="$t('confirm_registration')" style="width: 148px;height: 37px;color: #feffff;font-size: 16px;border: 0px;background-color: #3388e9;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" @click="oneClickRegistrationDetermine">
              <span>{{$t('confirm_registration')}}</span>
          </button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
    :visible.sync="isBindDialogVisible"
    width="284px"
    :before-close="handleClose">
    <div class="one_click_dialog" style="text-align: center;">
      <div class="title">
        {{$t('do_you_need_to_bind_the_newly_registered_game_account_to_this_account_and_the_game_account_will_receive_rewards')}}
      </div>
      <div class="button">
        <button type="button" class="ant-btn ant-dropdown-trigger" :title="$t('confirm_binding')" style="width: 148px;height: 37px;color: #feffff;font-size: 16px;border: 0px;background-color: #3388e9;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" @click="oneClickBindGame">
            <span>{{$t('confirm_binding')}}</span>
        </button>
      </div>
    </div>
  </el-dialog>

</div>
</template>
<style lang="scss">
@import url('../css/layouts.956413c0.chunk.scss');
@import url('../css/umi.0d36cc22.scss');
.one_click_dialog .radio{
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 90%;
  margin-top: 20px;
}
.one_click_dialog .title{
  font-size: 15px;
  color: #fff;
  width: 96%;
}
.one_click_dialog .radio .el-radio .el-radio__label{
  font-size: 13px;
  color: #fff;
}
.one_click_dialog .button{
  margin-top: 25px;
}
.bind_dialog_visible_middle{
  width: 2px;
  background-color: #474761;
  opacity: 0.55;
}
.bind_dialog_visible_left .check_password_or_code{
  color: #0397e3;
}
.bind_dialog_visible_left .account .el-input__inner{
  width: 348px;
  height: 37px;
  background-color: #2c333f;
  border: solid 1px #3e4d5e;
  color: #fff;
}
.bind_dialog_visible_left .email_or_mobile{
  color: #0397e3;
  font-size: 16px;
  margin-top: 30px;
  cursor:pointer;
  user-select:none;
}
.bind_dialog_visible_left .code_password .code{
  width: 350px;
  display: flex;
  justify-content: space-between;
}
.bind_dialog_visible_left .password_or_code div{
  cursor:pointer;
  user-select:none;
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bind_dialog_visible_left .button{
  margin-top: 30px;
}
.bind_dialog_visible_left .code_password .code .el-input__inner{
  width: 220px;
  height: 37px;
  background-color: #2c333f;
  border: solid 1px #3e4d5e;
  color: #fff;
}
.bind_dialog_visible_left .code_password .password .el-input__inner{
  width: 348px;
  height: 37px;
  background-color: #2c333f;
  border: solid 1px #3e4d5e;
  color: #fff;
}
.bind_dialog_visible_left .account .code{
  margin-top: 15px;
}
.bind_dialog_visible_left .account .password{
  margin-top: 15px;
}
.activity .bind_dialog{
  display: flex;
  justify-content: space-between;
}
.bind_dialog_visible_left{
  width: 330px;
  display: flex;
  flex-direction: column;
}
.bind_dialog_visible_left .title{
  color: #ffffff;
  font-size: 22px;
}
.bind_dialog_visible_left .password_or_code{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 200px;
  margin-top: 22px;
}
.bind_dialog_visible_right{
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding-right: 45px;
  text-align: center;
}
.activity_home{
  background-image: url('https://showmethemoney.fuxinsci.com/image_after_2019_06/2415/activity_home.jpg');
  background-repeat: no-repeat;
  height: 399px;
  background-position: 50% center;
}
.activity .el-dialog__header{
  padding: 0px;
}
.activity .el-dialog__body{
  background-color: #1c1c2c;
}
.activity_sign{
  background-image: url('https://showmethemoney.fuxinsci.com/image_after_2019_06/2416/activity_sign.jpg');
  background-repeat: no-repeat;
  height: 399px;
  background-position: 50% center;
}
.activity_reward{
  background-image: url('https://showmethemoney.fuxinsci.com/image_after_2019_06/2417/activity_reward.jpg');
  background-repeat: no-repeat;
  height: 679px;
  background-position: 50% center;
}
.activity_reward_text{
  background-color: #2c2e3e;
  text-align: center;
}
.activity_reward_text .text{
  width: 1144px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  color: #02c0f1;
  font-size: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.activity_create{
  height: 350px;
  background-color: #151525;
  line-height: 350px;
  margin: 0px auto;
  text-align: center;
}
</style>
<script>
export default {
  data(){
    var checkCodeAccount = (rule, value, callback) => {
      if(this.ruleForm.code_account == ''){
        this.mobileMsmIsAble = true;
        this.emailMsmIsAble = true;
        return
      }
      if(this.emailOrMobileValue == 'mobile'){
        //手机号
        let reg = /^1[0-9]{10}$/;
        if(!reg.test(this.ruleForm.code_account)){
          this.isCodeAccountError = true;
          this.mobileMsmIsAble = true;
          return callback(new Error(this.$t('phone_number_format_is_incorrect')));
        }
      }else{
        //邮箱
        let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!reg.test(this.ruleForm.code_account)){
          this.isCodeAccountError = true;
          this.emailMsmIsAble = true;
          return callback(new Error(this.$t('email_address_format_is_incorrect')));
        }
      }
      this.mobileMsmIsAble = false;
      this.emailMsmIsAble = false;
      this.isCodeAccountError = false;
    }
    var checkCodeCode = (rule, value, callback) => {
      if(this.ruleForm.code_code == ''){
        return
      }
      if(this.emailOrMobileValue == 'mobile'){
        //手机号
        if(this.ruleForm.code_code.length != 6){
          this.isCodeCodeError = true;
          return callback(new Error(this.$t('sms_verificationcode_format_is_incorrect')));
        }
      }else{
        //邮箱
        if(this.ruleForm.code_code.length != 6){
          this.isCodeCodeError = true;
          return callback(new Error(this.$t('the_format_of_the_mailbox_verification_code_is_incorrect')));
        }
      }
      this.isCodeCodeError = false;
    }

    var checkPasswordAccount = (rule, value, callback) => {
      if(this.ruleForm.password_account == ''){
        return
      }
      if(this.emailOrMobileValue == 'mobile'){
        //手机号
        let reg = /^1[0-9]{10}$/;
        if(!reg.test(this.ruleForm.password_account)){
          this.isCodeAccountError = true;
          return callback(new Error(this.$t('phone_number_format_is_incorrect')));
        }
      }else{
        //邮箱
        let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!reg.test(this.ruleForm.password_account)){
          this.isPasswordAccountError = true;
          return callback(new Error(this.$t('email_address_format_is_incorrect')));
        }
      }
      this.isPasswordAccountError = false;
    }
    var checkPasswordPassword = (rule, value, callback) => {
      if(this.ruleForm.password_password == ''){
        return
      }
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
      if(this.ruleForm.password_password.length < 8 || this.ruleForm.password_password.length > 32){
        this.isPasswordPasswordError = true;
        return callback(new Error(this.$t('please_enter_an_8_32_password')));
      }
      if(!reg.test(this.ruleForm.password_password)){
        this.isPasswordPasswordError = true;
        return callback(new Error(this.$t('password_must_contain_letters_and_numbers')));
      }
      this.isPasswordPasswordError = false;
    }
    return {
      bindDialogVisible: false,
      oneClickDialogVisible: false,
      checkdPasswordOrCodeValue: 'code',
      emailOrMobileValue: 'mobile',
      isCodeAccountError: false,
      isCodeCodeError: false,

      isPasswordAccountError: false,
      isPasswordPasswordError: false,

      passwordOrCodeData:[
        {
          label: this.$t('verification_code_binding'),
          value: 'code'
        },
        {
          label: this.$t('password_binding'),
          value: 'password'
        }
      ],
      rules: {
        code_account: [
            { validator: checkCodeAccount, trigger: 'change' }
        ],
        code_code: [
          { validator: checkCodeCode, trigger: 'change' }
        ],
        password_account: [
            { validator: checkPasswordAccount, trigger: 'change' }
        ],
        password_password: [
          { validator: checkPasswordPassword, trigger: 'change' }
        ]
      },
      emailMsmCd: 60,
      emailMsmIsAble: true,
      emailBindMsmIsSend: false,

      mobileMsmCd: 60,
      mobileMsmIsAble: true,
      mobileBindMsmIsSend: false,
      sign_type: '',
      ruleForm: {
        code_account: '',
        code_code: '',
        password_account: '',
        password_password: ''
      },
      one_sign_type: 'email',
      isBindDialogVisible: false,
      game_private_key: '',
      game_public_key: ''
    }
  },
  computed: {
    switch_to_email_binding: function () {
      if(this.checkdPasswordOrCodeValue == "code"){
        return this.$t('switch_to_email_verification_code_binding')
      }else{
        return this.$t('switch_to_email_password_binding')
      }
    },
    switch_to_mobile_binding: function () {
      if(this.checkdPasswordOrCodeValue == "password"){
        return this.$t('switch_to_phone_verification_code_binding')
      }else{
        return this.$t('switch_to_phone_password_binding')
      }
    },
    bindIsAble: function () {
      if(this.checkdPasswordOrCodeValue == "code"){
        //验证码绑定
        if(this.ruleForm.code_account != '' && this.ruleForm.code_code != '' && !this.isCodeAccountError && !this.isCodeCodeError){
          return false;
        }else{
          return true;
        }
      }else{
        //密码绑定
        if(this.ruleForm.password_account != '' && this.ruleForm.password_password != '' && !this.isPasswordAccountError && !this.isPasswordPasswordError){
          return false;
        }else{
          return true;
        }
      }
    },
    emailMsmButton: function () {
       if(this.emailMsmCd > 0 && this.emailMsmIsAble == true && this.isCodeAccountError == false  && this.ruleForm.code_account != '' && this.emailBindMsmIsSend && this.checkdPasswordOrCodeValue == 'code'){
           return "("+(this.emailMsmCd )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    mobileMsmButton: function () {
       if(this.mobileMsmCd > 0 && this.mobileMsmIsAble == true && this.isCodeAccountError == false  && this.ruleForm.code_account != '' && this.mobileBindMsmIsSend && this.checkdPasswordOrCodeValue == 'code'){
           return "("+(this.mobileMsmCd )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    },
    codeAccountPlaceHolder: function () {
        if(this.emailOrMobileValue == 'mobile'){
          return this.$t('please_enter_your_game_quiz_platform_mobile_number');
        }else{
          return this.$t('please_enter_your_game_quiz_platform_email_number');
        }
    },
    codeMsmPlaceHolder: function () {
        if(this.emailOrMobileValue == 'mobile'){
          return this.$t('please_enter_the_phone_verification_code');
        }else{
          return this.$t('please_enter_the_email_verification_code');
        }
    },
    passwordAccountPlaceHolder: function () {
        if(this.emailOrMobileValue == 'mobile'){
          return this.$t('please_enter_your_game_quiz_platform_mobile_number');
        }else{
          return this.$t('please_enter_your_game_quiz_platform_email_number');
        }
    },
    passwordPasswordPlaceHolder: function () {
        return this.$t('please_enter_your_account_password');
    },
    mobile_is_bind: function () {
        if(this.isLoggedIn){
            if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    email_is_bind: function (){
        if(this.isLoggedIn){
            if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    }
  },
  mounted() {
    if (this.currentJudgeIsNotLoggedIn) {
      location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
    }
  },
  methods: {
    oneClickBindGame: function () {
      this.isBindDialogVisible = false;
      this.postUrl('/www/user_center/one_click_bind_activity_account', {
          lang:this.currentLang,
          public_key: this.game_public_key,
          private_key: this.game_private_key
      }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/'}).catch(data => {  })
          }
      });
    },
    oneClickRegistrationDetermine: function () {
      this.sign_type = this.one_sign_type;
      this.oneClickDialogVisible = false;
      this.postOneClickRegistrationActivityAccount();
    },
    postOneClickRegistrationActivityAccount: function () {
      this.postUrl('/www/user_center/one_click_registration_activity_account', {
          lang:this.currentLang,
          sign_type: this.sign_type
      }).then((response) => {
          if(response.data.code == 20000){
            //绑定成功
            //重新加载用户数据
            this.getMemberInfo();
            this.game_private_key = response.data.private_key
            this.game_public_key = response.data.public_key
            this.isBindDialogVisible = true;
          }
      });
    },
    oneClickRegistration: function () {
      if(this.isLoggedIn){
          if(this.mobile_is_bind && this.email_is_bind){
            //手机号和邮箱都已进行绑定
            this.oneClickDialogVisible = true;
            return false;
          }else if(this.mobile_is_bind){
            //手机号进行绑定
            this.sign_type = "mobile"
          }else if(this.email_is_bind){
            //邮箱进行绑定
            this.sign_type = "email"
          }else{
            //都没绑定
            this.sign_type = ""
            return false;
          }
          this.postOneClickRegistrationActivityAccount()
      }else{
          location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      }
    },
    bindActivity: function () {
      this.postUrl('/www/user_center/bind_activity_account', {
          lang:this.currentLang,
          password_or_code: this.checkdPasswordOrCodeValue,
          email_or_mobile: this.emailOrMobileValue,
          code_account: this.ruleForm.code_account,
          code_code: this.ruleForm.code_code,
          password_account: this.ruleForm.password_account,
          password_password: this.ruleForm.password_password
      }).then((response) => {
          if(response.data.code == 20000){
            //绑定成功
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/'}).catch(data => {  })
          }
      });
    },
    emailMsmSend: function () {
      this.emailMsmIsAble = true;
      //发送邮箱验证码
      this.postUrl('/www/user_center/send_game_email_code', {
          lang:this.currentLang,
          email: this.ruleForm.code_account
      }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.emailBindMsmIsSend = true;
            this.timer1 = setInterval( () => {
              if(this.emailMsmCd > 1){
                 this.emailMsmIsAble = true;
                 this.emailMsmCd = this.emailMsmCd - 1;
             }else{
                 //重置
                 this.emailMsmIsAble = false;
                 this.emailMsmCd = 60;
                 this.emailBindMsmIsSend = false;
                 clearInterval(this.timer1)
             }
    　　　　}, 1000);
          }else{
            this.emailMsmIsAble = false;
          }
      });
　　 },
    mobileMsmSend: function () {
      this.mobileMsmIsAble = true;
      //发送手机验证码
      this.postUrl('/www/user_center/send_game_mobile_code', {
          lang:this.currentLang,
          mobile: this.ruleForm.code_account
      }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.mobileBindMsmIsSend = true;
            this.timer1 = setInterval( () => {
              if(this.mobileMsmCd > 1){
                 this.mobileMsmIsAble = true;
                 this.mobileMsmCd = this.mobileMsmCd - 1;
             }else{
                 //重置
                 this.mobileMsmIsAble = false;
                 this.mobileMsmCd = 60;
                 this.mobileBindMsmIsSend = false;
                 clearInterval(this.timer1)
             }
    　　　　}, 1000);
          }else{
            this.mobileMsmIsAble = false;
          }
       });
　　 },
    inputCodeAccount(){
      if(this.emailOrMobileValue == 'mobile'){
        //手机号
        this.ruleForm.code_account = this.ruleForm.code_account.replace(/[^0-9]/g,'');
      }else{
        //邮箱
        this.ruleForm.code_account = this.ruleForm.code_account.replace(/[\u4e00-\u9fa5]/ig,'');
      }
    },
    inputCodeCode(){
      this.ruleForm.code_code = this.ruleForm.code_code.replace(/[^0-9]/g,'');
    },
    inputCodeAccount(){
      if(this.emailOrMobileValue == 'mobile'){
        //手机号
        this.ruleForm.password_account = this.ruleForm.password_account.replace(/[^0-9]/g,'');
      }else{
        //邮箱
        this.ruleForm.password_account = this.ruleForm.password_account.replace(/[\u4e00-\u9fa5]/ig,'');
      }
    },
    inputPasswordPassword(){
    },
    checkEmailOrMobile(value){
      this.ruleForm.code_account = '';
      this.ruleForm.code_code = '';
      this.ruleForm.password_account = '';
      this.ruleForm.password_password = '';
      this.emailOrMobileValue = value;
      this.validateAllInput();
    },
    validateAllInput(){
      if(this.ruleForm.code_account != '' || this.isCodeAccountError){
        this.$refs.ruleForm.validateField('code_account');
      }
      if(this.ruleForm.code_code != '' || this.isCodeCodeError){
        this.$refs.ruleForm.validateField('code_code');
      }
      if(this.ruleForm.password_account != '' || this.isPasswordAccountError){
        this.$refs.ruleForm.validateField('password_account');
      }
      if(this.ruleForm.password_code != '' || this.isPasswordPasswordError){
        this.$refs.ruleForm.validateField('password_code');
      }
    },
    checkPasswordOrCode(value){
      this.ruleForm.code_account = '';
      this.ruleForm.code_code = '';
      this.ruleForm.password_account = '';
      this.ruleForm.password_code = '';
      this.checkdPasswordOrCodeValue = value;
    },
    handleClose(done) {
      done();
    },
    oneClickHandleClose(done) {
      done();
    },
    bindImmediately() {
      this.ruleForm.code_account = '';
      this.ruleForm.code_code = '';
      this.ruleForm.password_account = '';
      this.ruleForm.password_password = '';
      if(this.currentJudgeIsNotLoggedIn){
        //未登录
        this.$message({
          message: this.$t('please_log_in_first'),
          type: 'error'
        })
        return false
      }
      this.getUrl('/www/user_center/is_bind_game_account', {
          lang:this.currentLang
      }).then((response) => {
          if(response.data.code != 20000){
            this.$message({
              message: response.data.message,
              type: 'error'
            })
            return false;
          }else{
            this.bindDialogVisible = true;
          }
      });
    }
  }
}
</script>

