<template>
	<div class="pages-home-trades-main" style="margin-bottom: 78px">
    <!--
        <h1 style="font-size: 22px;color: var(--main-color-new)">{{$t('main_area')}}：</h1>
    -->
        <div class="pages-home-trades-tabs">
            <div class="ant-row">
                <div class="ant-col ant-col-20" style="width: 947px;display: flex;align-items: center;" onselectstart="return false;">
                    <a @click="$emit('change-qttt-area','zxq')" class="unit_type_list " :class="['zxq' === quotationTypeValueArea ? 'pages-home-trades-active' : null ]" :title="$t('self_selected_area')">{{$t('self_selected_area')}}</a>
                    <a v-for="item in quotationType" @click="$emit('change-qttt-area',item)"  class="unit_type_list " :class="[item === quotationTypeValueArea ? 'pages-home-trades-active' : null ]"  :title="item == 'stock' ? $t('stock')  + $t('market') : item.toUpperCase() + $t('market')">{{item == 'stock' ? $t('stock') : item.toUpperCase() }}{{$t('market')}}</a>
                </div>

                <div class="ant-col ant-col-4"  @click="$emit('show-drap-down')"  @click.stop="hideAlert($event)" style="width: 20%;margin-top: 1px;border-radius: 4px;margin-left: 12px;">
                  <div class="pages-home-trades-searchSelect ant-select ant-select-enabled ant-select-allow-clear">
                      <div class="ant-select-selection ant-select-selection--single" style="width: 220px;height: 35px;margin-left: 10px;margin-top: 10px;">
                          <div class="ant-select-selection__rendered">
                              <div unselectable="on" :class="[ isCheckdDrap ? 'ant-select-selection-selected-value' : 'ant-select-selection__placeholder']"  style="height: auto;display: block; user-select: none;line-height: 32px;margin-left: 46px;" >{{checkDrapName}}</div>
                              <div class="ant-select-search ant-select-search--inline" style="display: none;">
                                  <div class="ant-select-search__field__wrap">
                                      <input autocomplete="off" class="ant-select-search__field" value="">
                                      <span class="ant-select-search__field__mirror">&nbsp;</span>
                                  </div>
                              </div>
                          </div>

                          <div style="position: absolute; top: 0px; left: 0px; width: 100%;">
                            <div>
                              <div class="ant-select-dropdown ant-select-dropdown--single ant-select-dropdown-placement-bottomLeft" style="width: 242px; left: -13px; top: 45px;background-color: var(--home-list-col-input-bgcolor);" :class="  [isHideDrapdown ? 'ant-select-dropdown-hidden' : null ]">
                                <div id="710a95c3-2786-422c-851b-e710df284d2f" style="overflow: auto; transform: translateZ(0px);">
                                  <ul role="listbox" class="ant-select-dropdown-menu  ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical" tabindex="0">
                                    <li role="option" unselectable="on" class="ant-select-dropdown-menu-item" aria-selected="false" style="user-select: none;color: var(--main-color)" v-for="item in quoteCenterAreaPassCard" @click="$emit('check-drap',item.ask_unit,item.ask_unit_name)">{{item.ask_unit_name}}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <span class="ant-select-arrow" unselectable="on" style="user-select: none;margin-right: 176px;font-size: 16px;margin-top: -8px;">
                              <i aria-label="图标: search" class="anticon anticon-search ant-select-arrow-icon" v-if="!isCheckdDrap">
                                  <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                  </svg>
                              </i>

                               <i aria-label="图标: search" class="anticon anticon-search ant-select-arrow-icon" @click.stop="$emit('delete-drap',$event)" v-if="isCheckdDrap">
                                  <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                                  </svg>
                              </i>
                          </span>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="ant-table-wrapper pages-home-trades-table">
            <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                    <div class="ant-table ant-table-middle ant-table-scroll-position-left">
                        <div class="ant-table-content">
                            <div class="ant-table-body">
                                <div class="">
                                    <colgroup>
                                        <col style="width: 25px; min-width: 25px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 180px; min-width: 180px;">
                                        <col>
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                        <col style="width: 170px; min-width: 170px;">
                                    </colgroup>
                                    <div class="ant-table-thead" style="width: 100%;">
                                        <div style="width: 100%;justify-content: space-between;display: flex;background-color: var(--assets-rwd-home-list);"  onselectstart="return false;">
                                            <th class="" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div style="text-align: left;margin-left: 25px;line-height: 24px;">
                                                        <span class="ant-table-column-title">{{$t("transaction_pair")}}</span>
                                                        <span class="ant-table-column-sorter"></span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" style="" @click="$emit('order-list-area','last_price')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("latest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnArea == 'last_price' && originalArea == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnArea != 'last_price' || originalArea != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnArea == 'last_price' && originalArea == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnArea != 'last_price' || originalArea != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th"   @click="$emit('order-list-area','price_change_ratio')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("quote_change")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnArea == 'price_change_ratio' && originalArea == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnArea != 'price_change_ratio' || originalArea != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnArea == 'price_change_ratio' && originalArea == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnArea != 'price_change_ratio' || originalArea != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-area','high')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("highest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnArea == 'high' && originalArea == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnArea != 'high' || originalArea != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnArea == 'high' && originalArea == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnArea != 'high' || originalArea != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-area','low')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("lowest_price")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnArea == 'low' && originalArea == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnArea != 'low' || originalArea != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnArea == 'low' && originalArea == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnArea != 'low' || originalArea != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th class="ant-table-column-has-actions ant-table-column-has-sorters ant-table-column-has-sorters-th" @click="$emit('order-list-area','volume')" style="width: 20%;">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters storters_item">
                                                        <span class="ant-table-column-title">{{$t("24h_volume")}}</span>
                                                        <span class="ant-table-column-sorter" style="margin-top: 4px;">
                                                            <div title="排序" class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full inner_item">
                                                                <i aria-label="图标: caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up ">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2028/arrow_up_checkd.png" v-if="checkOrderColumnArea == 'volume' && originalArea == 'asc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2029/arrow_up.png" v-if="checkOrderColumnArea != 'volume' || originalArea != 'asc'" width="8px"></img>
                                                                </i>
                                                                <i aria-label="图标: caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down" style="margin-top: 4px">
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2026/arrow_down_checkd.png" v-if="checkOrderColumnArea == 'volume' && originalArea == 'desc'" width="8px"></img>
                                                                    <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2027/arrow_down.png" v-if="checkOrderColumnArea != 'volume' || originalArea != 'desc'" width="8px"></img>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </th>
                                        </div>
                                    </div>
                                    <div class="ant-table-tbody">
                                        <div class="ant-table-row ant-table-row-level-0 data_content" v-for="(item, i) in quoteCenterAreaData" style="justify-content: space-between;display: flex;"  :class = "[i % 2 == 0 ? '' : 'odd']" @click="goTrading(item.id)">
                                            <td class="" style="display: flex;width: 20%;">
                                                <span class="pages-home-trades-favorite" @click="$emit('check-market-favorite',item.id)" @click.stop="hideAlert($event)">
                                                    <i class="iconfont " :class="[currentMainAreaFavoriteIds.indexOf(item.id) > '-1' ? 'icon-xingxing' : 'icon-xingxu']"></i>
                                                </span>
                                                <div class="pages-home-trades-tradeBoom" style="padding-left: 10px;">{{item.ask_unit_upcase}}/{{item.bid_unit_upcase}}</div>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                <div>
                                                    <span>{{item.last_price}}</span>
                                                </div>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                <span :style="{'color': item.price_change_ratio.indexOf('-') == -1 ? 'var(--rise-color)' : 'var(--down-color)'}">{{item.price_change_ratio}}</span>
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.high}}
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.low}}
                                            </td>
                                            <td class="ant-table-column-has-actions ant-table-column-has-sorters" style="width: 20%;">
                                                {{item.volume}}
                                            </td>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css" scoped>
.unit_type_list{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.5s;
}
.data_content{
    animation: fadeEffect .5s;
}
.ant-table-tbody{
    width: 100%;
    height: 800px;
    overflow: auto;
}
@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}
.ant-table-row:hover{
    background-color: var(--box-hover-bg);
}
.ant-table-column-has-sorters-th:hover{
    background-color: var(--home-main-line-bgcolor);
}
td{
    padding: 12px 8px;
    text-align: center;
}
th{
    padding: 12px 8px;
    cursor: pointer;
    text-align: center;
    background-color: var(--home-trade-header-bgcolor);
    color: var(--main-color-middle);
}
.odd{
    background-color: var(--trades-list-line-odd)
}
.icon-xingxing{
    color:rgb(250, 173, 20);
    font-size: 12px;
}
.ant-table-column-has-sorters{
    text-align: center;
}
.icon-xingxu{
    color:var(--trade-color-light);
    font-size: 12px;
}
.ant-table-column-title {
    color: #9b9b9b;
}
.storters_item{
    display: flex;
    justify-content: center;
    line-height: 24px;
}
.inner_item{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String,
    isHideDrapdown: Boolean,
    isCheckdDrap: Boolean,
    checkDrapName: String,
    quotationTypeValueArea: String,
    quoteCenterAreaData: Array,
    quoteCenterAreaPassCard: Array,
    checkOrderColumnArea: String,
    originalArea: String,
    quotationType: Array
  },
  watch: {
    currentWwwHomeTradesMainAreaList(newVal) {
        if (newVal.length === 0){
            return
        }
        //websocket数据更新 页面及时渲染
        this.$emit('reload-list-data')
    }
  },
  created(){
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    goTrading(market_id){
        //到达trading币种页
        window.open(Vue.getValueByKey('trading_path')+ '/exchange/' + market_id + '?lang=' + this.currentLang, "_blank");
    },
    hideAlert(){

    }
  }
}
</script>
