import { render, staticRenderFns } from "./HomeTradesMainInnovationZone.vue?vue&type=template&id=13440761&scoped=true"
import script from "./HomeTradesMainInnovationZone.vue?vue&type=script&lang=js"
export * from "./HomeTradesMainInnovationZone.vue?vue&type=script&lang=js"
import style0 from "./HomeTradesMainInnovationZone.vue?vue&type=style&index=0&id=13440761&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13440761",
  null
  
)

export default component.exports