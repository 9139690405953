<template style="height: 100%;">
<div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);width: 100%;height: 100%;">
  <body class="body">
      <div class="invite">
        <div class="invite_logo">
          <img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2012/invite_logo.png" />
          <div class="invite_logo_text">{{$t('invitation_card')}}</div>
        </div>
        <div class="invite_code">
          {{$t('my_invitation_code')}}：<span class="code">{{invitation_code}}</span>
        </div>
        <div class="invite_msg">
          {{$t('the_following_is_your_unique_invitation_code_you_can_share_the_screenshot_of_the_page_to_a_weChat_friend')}}，<br>{{$t('let_the_other_party_press_and_hold_to_register')}}
        </div>
        <div id="qrCode" class="invite_qr_code_div" ref="inviteQrCodeDiv"></div>
      </div>
  </body>
</div>
</template>
<style lang="scss" scoped>
html,body{
	min-height:100%;
	overflow:hidden;
}
.body{
    background-image: url('https://showmethemoney.fuxinsci.com/image_after_2019_06/2013/invite.png');
    background-size: 2000px;
    background-repeat: no-repeat;
    height: 100%;
}
.invite{
  text-align: center;
  display: flex;
  flex-direction: column;
}
.invite img{
  width: 296px;
}
.invite_logo{
  margin-top: 50px;
}
.invite_code{
  margin-top: 150px;
  font-size: 32px;
  color: #ffffff;
}
.invite_code .code{
  color: #a759ff;
}
.invite_msg{
  margin-top: 15px;
  font-size: 29px;
  color: #ffffff;
}
.invite_qr_code_div{
  margin:0 auto;
  margin-top: 35px;
  border: 5px solid #ffffff;
}
.invite_logo_text{
  margin-top: -188px;
  margin-left: 3px;
  font-size: 43px;
  color: #ffffff;
}
</style>
<script>
import QRCode from 'qrcodejs2';
import Vue from 'vue'
export default {
  mounted: function () {
	  this.$nextTick(function () {
	   this.bindQRCode();
	  })
  },
  methods: {
    bindQRCode: function () {
        new QRCode(this.$refs.inviteQrCodeDiv, {
          text: Vue.getValueByKey('cas_path')+'/sign_up?lang='+this.currentLang+'&invitation_code=' + this.invitation_code,
          width: 140,
          height: 140,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
    }
  },
  computed: {
  	invitation_code: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.invitation_code
        }else{
            return false;
        }
    },
  }
}
</script>

