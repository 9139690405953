<template>
	<div class="pages-home-index-features" ref="features">
        <div class="pages-home-index-title" style="margin: 117px 0 35px;">{{currentHomeText}}{{$t("the_world_is_leading_digital_asset_trading_platform")}}</div>
        <div class="pages-home-index-desc">
            <p>{{$t("we_take_security_seriously_and_build_the_reputation_of_the_most_trusted_space_store_most_of_the_digital_assets_retained_on")}} {{currentHomeText}} {{$t("in_secure_offline_storage_full_respect_the_trading_rules_of_financial_markets_establish_a_fair_and_equitable_trading_mechanism_to_protect_the_legitimate_rights_and_interests_of_traders")}}.</p>
        </div>
        <div class="ant-row pages-home-index-pics" :class="[isShowPics ? 'pics-active' : '']">
            <div class="ant-col ant-col-8">
                <div class="features_div_class"><img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2009/features1.png" alt=""></div>
                <h3>{{$t("the_safest_digital_asset_trading_platform")}}</h3>
                <p>{{currentHomeText}} {{$t("enables_ssl_and_2fa_for_98_digital_assets")}}，{{$t("use_cold_storage_and_run_multiple_times_a_day")}}{{$t("encryption_and_distributed_backup")}}</p>
            </div>
            <div class="ant-col ant-col-8">
                <div class="features_div_class"><img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2010/features2.png" alt=""></div>
                <h3>{{$t("compliance_and_legal_trading_platform")}}</h3>
                <p>{{$t("in_order_to_comply_with_relevant_regulations_of_government_regulation")}}，{{currentHomeText}} {{$t("is_the_most_compliant")}}{{$t("digital_asset_exchange_actively_applying_for_various_relevant_regulatory_authorities")}}{{$t("proven_and_maintain_close_contact_with_relevant_regulatory_agencies")}}</p>
            </div>
            <div class="ant-col ant-col-8">
                <div class="features_div_class"><img src="https://showmethemoney.fuxinsci.com/image_after_2019_06/2011/features3.png" alt=""></div>
                <h3>{{$t("superior_core_technology")}}</h3>
                <p>{{currentHomeText}} {{$t("the_base_code_is_developed_entirely_by_its_own_team_by_using_us")}}，{{$t("the_invented_hybrid_engine_which_can_process_nearly_10000_orders_per_second")}}，{{$t("make")}} {{currentHomeText}} {{$t("one_of_the_fastest_exchanges_on_the_world_market_today")}}</p>
            </div>
        </div>
    </div>
</template>
<style type="text/css" scoped>
    .pics-active{
        animation:dong 0.5s;
        animation-fill-mode: forwards;/*当动画完成时，动画会停留在最后一帧。*/
    }
    @keyframes dong {
        0% {
           transform: translate(0px, 100px);
        }
        100% {
           transform: translate(0px, 00px);
        }
    }
    .features_div_class{
        height: 250px;
        line-height: 250px;
    }
    .ant-row div p{
        width: 380px;
        margin-left: 10px;
    }
</style>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String
  },
  data() {
    return {
        isShowPics: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      let top = this.$refs.features.offsetTop
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > (top - 600)){
        this.isShowPics = true;
      }
    },
  },
  beforeDestroy(){
    window.removeEventListener("scroll",this.handleScroll)
  },
}
</script>
