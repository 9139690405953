import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import messages from './langs'

Vue.use(VueI18n)
lang = 'zh'
try {
  lang = JSON.parse(localStorage.getItem('STORAGE_VARIABLE')).lang
} catch (error) {
  console.warn(error)
}
const i18n = new VueI18n({
  locale: localStorage.lang,
  messages,
})

locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换

export default i18n
